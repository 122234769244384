export const Activities = {
  "TSS": {
    "5G": 11,
    "4G": 11
  },
  "Implementation (1xRAT) (RF ICI+ADD2)": {
    default: 52.07,
    tss: 63.07
  },
  "Implementation (1xRAT) (RF+BB ICI+ADD2)": {
    default: 56.57,
    tss: 67.57
  },
  "Implementation (2xRAT) (RF+BB ICI+ADD2)": {
    default: 64,
    tss: 75
  },
  "Implementation (1xRAT)": {
    default: 46.28,
    tss: 76.28
  },
  "Implementation (2xRAT)": {
    default: 94.27,
    tss: 105.27
  },
  "Implementation 1xBAND expansion (RF or RRU and all sectors). Add band to existing site or increase MIMO": {
    default: 25.55,
    tss: 36.55
  },
  "Implementation 1xBAND upgrade (RF or RRU and all sectors). Add band to existing site or increase MIMO": {
    default: 36.43,
    tss: 47.43
  },
  "Implementation 2xBAND expansion  (RF or RRU and all sectors). Add band to existing site or increase MIMO": {
    default: 49.01,
    tss: 60.01
  },
  "Implementation 2xBAND upgrade  (RF or RRU and all sectors). Add band to existing site or increase MIMO": {
    default: 55.31,
    tss: 66.31
  },
  "Implementation 3xBAND expansion  (RF or RRU and all sectors). Add band to existing site or increase MIMO": {
    default: 95.54,
    tss: 106.54
  },
  "Implementation 3xBAND upgrade  (RF or RRU and all sectors). Add band to existing site or increase MIMO": {
    default: 76.7,
    tss: 87.7
  },
  "Implementation expansion  (RF or RRU and all sectors). Add band to existing site or increase MIMO (Scope 1)": {
    default: 31.9,
    tss: 42.9
  },
  "Implementation expansion  (RF or RRU and all sectors). Add band to existing site or increase MIMO (Scope 2)": {
    default: 32.83,
    tss: 43.83
  },
}

export const Additionals = {
  "Revisita": 145040,
  "Retiro de antenas por sector": 34000,
  "Instalación de BBU": 219520,
  "SWAP DE ANTENA": 110157,
  "Instalación de módulo rectificador": 92285,
  "Desmontaje y Retiro de equipos": 125783,
  "Traspaso de cargas": 340529,
  "Suministro e instalacion / Reemplazo de breaker en planta de energía": 20000,
  "Instalación de Elemento Pasivo (splitter, combinador, duplexor, diplexor u otros)": 61600,
  "Traslado de  equipo de radio": 122470,
  "Traslado de  materiales 300km": 150000,
  "Movimiento / Traslado  de Antenas": 85000,
  "Desinstalación de Feeders": {
    base: 150000,
    multiplier: 4489
  },
  "Distribución equipo de radio": 183637,
  "Instalación de Soportes Azotea": 280000,
  "Instalación de Soportes Monoposte": 268000,
  "Instalación de Soportes Torre Auto soportada / ventada": 98726,
  "Segunda alimentacion FPFH": 65000,
  "Desinstalacion RRU": 34000,
  "Transporte Material": 25157
}
