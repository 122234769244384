<template>
  <v-card
    rounded="lg"
    height="100%"
    style="overflow: auto"
    class="d-flex flex-column"
    color="transparent"
    >
    <v-card-title
      class="py-1 primary white--text lower-opacity"
      >
      Items en la tarea
    </v-card-title>

    <v-card-text
      class="lower-background-opacity grow text--primary py-3"
      style="height: calc(100vh - 360px); overflow: auto"
      >
      <div>
        <template
          v-for="(stock, i) in stocks"
          >
          <item
            :idx="i"
            :stock="stock"
            ></item>
        </template>
      </div>
    </v-card-text>

    <v-card-actions
      class="lower-background-opacity"
      >
      <div
        class="grow d-flex justify-end"
        style="gap: 12px"
        v-if="stocks.length"
        >
        <v-btn
          color="primary"
          depressed
          class="rounded-lg"
          @click="redirect"
          >
          Realizar egreso
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

import { Stocks } from '@/graphql/queries/stocks'

import Item from '@/components/shared/Item'

export default {
  data: () => ({
    stocks: []
  }),

  props: {
    id: {
      required: false,
      type: Number | String
    },
  },

  watch: {
    id (val) {
      if (val) {
        this.fetchStocks ()
      } else {
        this.stocks = []
      }
    }
  },

  computed: {
    ...mapGetters(['currentUser'])
  },

  created () {
    if (this.id) {
      this.fetchStocks ()
    }
  },

  methods: {
    fetchStocks () {
      this.$apollo.query({
        query: Stocks,
        variables: {
          rolloutId: this.id
        },
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.stocks = res.data.stocks
      })
    },

    reload (id) {
      this.$emit('reload', this.id)
      this.fetchStocks()
    },

    redirect () {
      var url = `${process.env.VUE_APP_WAREHOUSES_URL}?tab=2&rid=${this.id}`
      window.open(url, '_blank');
    }
  },

  components: {
    Item
  }
}
</script>
