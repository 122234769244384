import gql from 'graphql-tag'

export const CreateRollout = gql`
  mutation createRollout($input: CreateRolloutInput!) {
    createRollout(input: $input) {
      clientMutationId
      rollout {
        id
      }
    }
  }
`

export const EditRollout = gql`
  mutation editRollout($input: EditRolloutInput!) {
    editRollout(input: $input) {
      clientMutationId
      rollout {
        id
      }
    }
  }
`

export const CancelRollout = gql`
  mutation cancelRollout($input: CancelRolloutInput!) {
    cancelRollout(input: $input) {
      clientMutationId
      rollout {
        id
      }
    }
  }
`
