<template>
  <div
    class="pa-3 fill-height d-flex flex-column align-stretch"
    >
    <site-header
      :site="site"
      @search="searchSite"
      >
    </site-header>

    <div
      class="mt-3 grow"
      >
      <site-main
        @updateState="updateState"
        @refresh="fetchRollouts"
        :rollouts="rollouts"
        :site="site"
        ></site-main>
    </div>
  </div>
</template>

<script>
import { Site } from '@/graphql/queries/sites'
import { Rollouts } from '@/graphql/queries/rollouts'

import SiteHeader from '@/components/sites/Header'
import SiteMain from '@/components/sites/Main'

export default {
  data: () => ({
    site: {},
    rollouts: [],
    state: ['assigned', 'installing', 'installed', 'pre_approved', 'approved', 'partial', 'partial_billed', 'total'],
  }),

  components: { SiteHeader, SiteMain },

  methods: {
    searchSite (id) {
      this.$apollo.query({
        query: Site,
        variables: {
          id: id,
        },
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.site = res.data.site

        this.fetchRollouts ()
      })
    },

    fetchRollouts () {
      this.$apollo.query({
        query: Rollouts,
        variables: {
          siteId: this.site.id,
          projectId: this.$route.params.id,
          state: this.state
        },
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.rollouts = res.data.rollouts
      })
    },

    updateState (state) {
      this.state = state
      
      this.$nextTick ( () => {
        this.fetchRollouts ()
      })
    }
  }
}
</script>
