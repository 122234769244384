<template>
  <div
    v-if="rollout"
    style="height: 100%"
    >
    <nokia-detail
      v-if="project.projectType == 'nokia'"
      :rollout="rollout"
      @reload="$emit('reload', rollout.id)"
      ></nokia-detail>

    <huawei-detail
      v-if="project.projectType == 'huawei'"
      :rollout="rollout"
      @reload="$emit('reload', rollout.id)"
      ></huawei-detail>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const NokiaDetail = () => import('@/components/sites/main/activities/nokia/Detail.vue')
const HuaweiDetail = () => import('@/components/sites/main/activities/huawei/Detail.vue')

export default {
  computed: {
    ...mapGetters(['project'])
  },

  props: {
    rollout: {
      type: Object,
      required: true
    }
  },

  components: {
    NokiaDetail,
    HuaweiDetail
  }
}
</script>
