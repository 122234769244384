<template>
  <v-dialog
    v-model="dialog"
    width="1000"
    >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        depressed
        color="error"
        class="rounded-lg py-5"
        v-bind="attrs"
        v-on="on"
        >
        Eliminar seleccionados
      </v-btn>
    </template>

    <v-card>
      <v-card-title
        class="py-2 error justify-center white--text lower-opacity"
        >
        ¡CUIDADO!
      </v-card-title>

      <v-card-text
        class="pt-6 text-center text-subtitle-1"
        >
        ¿Seguro que desea eliminar las POs seleccionadas?
      </v-card-text>

      <v-card-actions
        class="justify-center"
        >
        <v-btn
          depressed
          class="rounded-lg py-5"
          color="primary"
          @click="dialog = false"
          >
          No
        </v-btn>

        <v-btn
          depressed
          class="rounded-lg py-5"
          color="error"
          @click="deletePos"
          >
          Sí
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { DeletePo } from '@/graphql/mutations/pos'

export default {
  data: () => ({
    dialog: false
  }),

  props: {
    ids: {
      required: true,
      type: Array
    }
  },

  methods: {
    deletePos () {
      this.$apollo.mutate({
        mutation: DeletePo,
        variables: {
          input: {
            ids: this.ids
          }
        }
      }).then ( res => {
        this.$emit('reload')
        this.dialog = false
      })
    }
  }
}
</script>
