<template>
  <v-form
    v-model="valid"
    v-if="pricing"
    ref="form"
    lazy-validation
    >
    <v-row>
      <v-col
        cols="4"
        class="pr-0"
        >
        <v-autocomplete
          outlined
          :items="workTypes"
          label="Tipo de trabajo"
          v-model="attributes.workType"
          hide-details
          dense
          style="background-color: white"
          ></v-autocomplete>
      </v-col>
    </v-row>

    <template
      v-for="activity in attributes.huaweiActivitiesAttributes"
      >
      <v-row
        v-if="!activity._destroy"
        >
        <v-col
          cols="4"
          class="pr-0 pb-0"
          >
          <v-autocomplete
            outlined
            :items="Object.keys(pricing)"
            label="Categoría"
            v-model="activity.category"
            dense
            style="background-color: white"
            ></v-autocomplete>
        </v-col>

        <v-col
          cols="5"
          class="pr-0 pb-0"
          >
          <v-autocomplete
            outlined
            :disabled="!activity.category"
            :items="pricing[activity.category]"
            item-text="description"
            item-value="description"
            label="Descripción"
            v-model="activity.description"
            dense
            mandatory
            style="background-color: white"
            :rules="[
            v => !!v || 'Campo obligatorio',
            ]"
            ></v-autocomplete>
        </v-col>

        <v-col
          cols="2"
          class="pr-0 pb-0"
          >
          <v-text-field
            outlined
            label="Cantidad"
            v-model="activity.quantity"
            dense
            style="background-color: white"
            ></v-text-field>
        </v-col>

        <v-col
          cols="1"
          class="pl-0 text-right"
          >
          <v-btn
            icon
            color="error"
            @click="destroyActivity(activity)"
            >
            <v-icon>
              mdi-delete
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>

    <div
      class="text-right"
      >
      <v-btn
        outlined
        color="success"
        @click="pushActivity"
        >
        Añadir actividad
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import { EditRollout } from '@/graphql/mutations/rollouts'
import { HuaweiPricing } from '@/graphql/queries/activities'
import { Activities } from '@/utils/prices'

export default {
  data: () => ({
    attributes: {
      workType: 'Implementación',
      additionalsAttributes: [],
      huaweiActivitiesAttributes: [],
    },
    workTypes: ['Implementación', 'Power', 'Clean Up', 'TSS'],
    pricing: null,
    valid: true
  }),

  props: {
    rollout: {
      type: Object,
      required: true
    }
  },

  created () {
    this.fetchPricing ()
    this.attributes.workType = this.rollout.workType

    if (this.rollout.additionals.length) {
      this.rollout.additionals.forEach( (additional) => {
        delete additional.__typename
        delete additional.closed
        delete additional.totalAmount

        this.attributes.additionalsAttributes.push(additional)
      })
    }

    if (this.rollout.huaweiActivities.length) {
      this.rollout.huaweiActivities.forEach( (activity) => {
        delete activity.__typename
        delete activity.closed
        delete activity.checked
        delete activity.linkId

        this.attributes.huaweiActivitiesAttributes.push(activity)
      })
    } else {
      this.pushActivity()
    }
  },

  methods: {
    fetchPricing () {
      this.$apollo.query({
        query: HuaweiPricing,
      }).then ( res => {
        this.pricing = res.data.huaweiPricing.reduce ( (r, i) => {
          r[i.category] = r[i.category] || []
          r[i.category].push(i)
          return r
        }, Object.create(null)) 
      })
    },

    editRollout () {
      if (this.$refs.form.validate()) {
        this.$apollo.mutate({
          mutation: EditRollout,
          variables: {
            input: {
              id: this.rollout.id,
              attributes: this.attributes
            }
          }
        }).then ( res => {
          this.$emit('reload', res.data.editRollout.rollout.id)
        })
      }
    },

    pushActivity () {
      this.attributes.huaweiActivitiesAttributes.push({
        id: null,
        quantity: null,
        number: null,
        category: null,
        activityType: null,
        item: null,
        description: null,
        uom: null,
        price: null,
      })
    },

    destroyActivity (activity) {
      activity._destroy = 1
      this.$forceUpdate()
    },
  },
}
</script>
