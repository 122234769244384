<template>
  <div
    class="text--primary"
    >
    <v-snackbar
      v-model="updated"
      color="success"
      >
      ¡Tecnología actualizada con éxito!
    </v-snackbar>
    <template
      v-for="line, idx in po.lines"
      >
      <div
        style="gap: 12px; border-bottom: 1px solid black; border-left: 1px solid black; border-right: 1px solid black"
        class="d-flex align-center px-2"
        >
        <div
          class="shrink"
          >
          <v-btn 
            class="mr-1"
            icon
            color="transparent"
            disabled
            small
            />
        </div>
        <div
          class="grow py-3"
          >
          <v-row
            class="align-stretch"
            >
            <v-col
              cols="1"
              class="d-flex align-center justify-center"
              style="border-right: 1px solid black;"
              >
              {{ po.number }}
            </v-col>

            <v-col
              cols="3"
              class="d-flex align-center justify-center text-center"
              style="border-right: 1px solid black;"
              >
              {{ line.description }}
            </v-col>

            <v-col
              cols="1"
              class="d-flex align-center justify-center"
              style="border-right: 1px solid black;"
              >
              {{ line.quantity }}
            </v-col>

            <v-col
              cols="1"
              class="d-flex align-center justify-center"
              style="border-right: 1px solid black;"
              >
              <v-select
                :items="['4G', '5G']"
                v-model="technology[idx]"
                label="Tecnología"
                :disabled="!!po.billedAt"
                hide-details
                dense
                solo
                flat
                @input="edit(idx)"
                ></v-select>
            </v-col>

            <v-col
              cols="1"
              class="d-flex align-center justify-center"
              style="border-right: 1px solid black;"
              >
              {{ line.netPrice }}
            </v-col>

            <v-col
              cols="1"
              class="d-flex align-center justify-center"
              style="border-right: 1px solid black;"
              >
              {{ line.totalPrice }}
            </v-col>

            <v-col
              cols="4"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
              >
              {{ line.pciDescription }}
            </v-col>
          </v-row>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { EditPoLine } from '@/graphql/mutations/pos'

export default {
  data: () => ({
    updated: false,
    technology: []
  }),

  props: {
    po: {
      required: true,
      type: Object
    }
  },

  created () {
    this.po.lines.forEach ( (line, idx) => {
      this.technology[idx] = line.technology
    })
  },

  methods: {
    edit (idx) {
      this.$apollo.mutate({
        mutation: EditPoLine,
        variables: {
          input: {
            id: this.po.lines[idx].id,
            attributes: {
              technology: this.technology[idx]
            }
          }
        }
      }).then ( res => {
        this.updated = true
        this.$emit('reload')
      })
    }
  }
}
</script>
