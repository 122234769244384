<template>
  <v-card
    height="100%"
    rounded="lg"
    class="d-flex flex-column"
    color="transparent"
    >
    <v-card-title
      class="py-0 pr-0 primary white--text lower-opacity"
      >
      <div
        class="d-flex flex-row justify-space-between grow align-center"
        >
        <div
          class="py-1"
          >
          Rollout
        </div>

        <div>
          <v-btn-toggle
            v-model="state"
            class="align-self-stretch"
            color="primary"
            tile
            dense
            >
            <v-btn
              class="py-5 caption font-weight-medium text--primary"
              :value="['assigned', 'installing', 'installed', 'pre_approved', 'approved', 'partial', 'partial_billed', 'total']"
              >
              Pendiente ({{ counting.pending }})
            </v-btn>

            <v-btn 
              class="py-5 caption font-weight-medium text--primary"
              :value="['closed']"
              >
              Terminado ({{ counting.closed }})
            </v-btn>

            <v-btn
              class="py-5 caption font-weight-medium text--primary"
              :value="['cancelled']"
              >
              Cancelado ({{ counting.cancelled }})
            </v-btn>
          </v-btn-toggle>
          <create
            @search="placeSearch"
            @reload="reload"
            :site="site"
            :rollout="rollout"
            :key="band"
            ></create>
        </div>
      </div>
    </v-card-title>

    <v-card-text
      class="pt-6 lower-background-opacity grow"
      v-if="rollout"
      :key="JSON.stringify(rollout)"
      >
      <div
        style="gap: 12px"
        class="d-flex flex-row"
        >
        <v-autocomplete
          outlined
          label="ID Tarea"
          v-model="search"
          :items="rollouts"
          item-text="id"
          item-value="id"
          hide-details
          dense
          style="background-color: white"
          ></v-autocomplete>

        <v-text-field
          outlined
          label="Status"
          v-if="rollout"
          :value="$t('status.rollout.' + (rollout || {}).status)"
          readonly
          hide-details
          dense
          style="background-color: white"
          ></v-text-field>
      </div>

      <v-divider
        class="my-6"
        ></v-divider>

      <div
        v-if="rollout"
        class="mt-3"
        >
        <template
          v-for="field in fields"
          >
          <v-text-field
            outlined
            :label="$t('fields.rollout.' + field)"
            :value="rollout[field]"
            readonly
            hide-details
            dense
            style="background-color: white"
            class="mb-3"
            ></v-text-field>
        </template>

        <v-autocomplete
          outlined
          :items="assignees"
          label="Responsables"
          :value="rollout.assignees"
          readonly
          hide-details
          dense
          chips
          small-chips
          multiple
          style="background-color: white"
          class="mb-3"
          append-icon=""
          >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              small
              :color="rollout.supervisor == data.item ? 'success' : ''"
              >
              {{ data.item }}
            </v-chip>
          </template>
        </v-autocomplete>

        <v-textarea
          outlined
          :label="$t('fields.rollout.obs')"
          :value="rollout['obs']"
          rows="3"
          readonly
          hide-details
          dense
          style="background-color: white"
          class="mb-3"
          ></v-textarea>
      </div>
    </v-card-text>

    <v-card-actions
      class="lower-background-opacity"
      >
      <div
        v-if="rollout"
        class="grow text-right"
        :key="rollout.id"
        >
        <cancel
          v-if="currentUser && (['rollout', 'management', 'shibui'].includes(currentUser.currentRoleName)) && rollout.id && !(['closed', 'cancelled'].includes(rollout.status))&& !rollout.huaweiPos.length && !rollout.pos.length"
          :id="rollout.id"
          @reload="refresh"
          ></cancel>
        <edit
          :rollout="rollout"
          @reload="reload"
          ></edit>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { RolloutCounting } from '@/graphql/queries/rollouts'

import Cancel from './rollout/Cancel'
import Edit from './rollout/Edit'
import Create from './rollout/Create'

export default {
  data: () => ({
    band: 0,
    counting: {},
    state: ['assigned', 'installing', 'installed', 'pre_approved', 'approved', 'partial', 'partial_billed', 'total'],
    fields: [
      'assignationDate',
      'startAt',
      'endsAt',
      'documentationSentAt',
    ],
    search: null,
    assignees: [
      "CRISTOHER RUZ COTAL",
      "DAVID LEIVA CAYULEO",
      "IGNACIO MENESES GUERRA",
      "JONATHAN ANTONIO QUIÑONES GUZMAN",
      "MARCELO GARRIDO NUÑEZ",
      "RODRIGO POZO TRONCOZO",
      "VICTOR MANUEL ORMAZABAL ARAVENA",
      "WILLIAMS ZUÑIGA GODOY",
      "ANDRES ANTONIO GOMEZ LOVERA",
      "KLIBER GERARDO DIAZ GARABAN",
      "ARTURO QUILLA VALENCIA",
      "JONATHAN CASTRO GARRIDO",
      "AGUSTIN APABLAZA ZUÑIGA",
      "FRED NELSON CID ZUÑIGA",
      "ENZO RODRIGO RODRIGUEZ TORO",
      "FRANCISCO ASTORGA CACERES",
      "ARDANY TIMOTE PRADO",
      "CARLOS ORDOÑEZ ABELLO",
      "EDUARDO HERNANDEZ MARTINEZ",
      "EDUARDO VIDAL OLAVE",
      "ELIAS ALBERTO GALDAMEZ NAVARRETE",
      "JONATHAN PAREDES SILVA",
      "LUIS ALEJANDRO DELGADO GUZMAN",
      "LUIS CARLOS MUJICA LOVERA",
      "PATRICIO LOPEZ REYES",
      "SEBASTIAN CARRASCO CID",
      "ISAIAS GONZALEZ RUDIO",
      "GERARDO CONCHA",
      "PEDRO PEÑA",
    ],
  }),

  props: {
    rollouts: {
      type: Array,
      required: true
    },
    
    rollout: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    },

    site: {
      type: Object,
      required: true,
    },
  },

  watch: {
    rollouts (val) {
      if (val[0]) {
        if (!this.search || !(val.map( v => v.id ).includes(this.search))) {
          this.search = val[0].id
        }
      } else {
        this.search = 0
      }

      this.fetchRolloutCounting ()
    },
    
    search (val) {
      if ( val != this.$route.query.task_id ) {
        this.$router.replace({ name: 'rollout', params: { id: this.$route.params.id}, query: { site_id: this.$route.query.site_id, task_id: val } })
      }

      this.$emit('search', val)
    },

    state (val) {
      this.$emit('updateState', val)
    }
  },

  computed: {
    ...mapGetters(['currentUser', 'project'])
  },

  created () {
    if (this.$route.query.task_id) {
      this.search = this.$route.query.task_id
    } else if (this.rollouts[0]) {
      this.search = this.rollouts[0].id
    }

    this.fetchRolloutCounting ()
  },

  methods: {
    fetchRolloutCounting () {
      this.$apollo.query({
        query: RolloutCounting,
        variables: {
          projectId: this.$route.params.id,
          siteId: this.$route.query.site_id
        },
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.counting = res.data.rolloutCounting
      })
    },

    placeSearch (id) {
      this.rollouts.push({ id: id })

      this.$nextTick( () => {
        this.search = id
      })
    },

    reload (id) {
      this.$emit('search', id)
      this.band += 1
      this.$forceUpdate()
    },

    refresh () {
      this.$emit('refresh')
    }
  },

  components: { Edit, Create, Cancel },
}
</script>
