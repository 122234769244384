<template>
  <v-dialog
    v-model="dialog"
    :key="rollout.id + '_' + pos.length + '_' + rollout.nokiaActivities.length + '_' + rollout.huaweiActivities.length"
    width="2000"
    height="100vh"
    scrollable
    >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="success"
        class="rounded-lg py-5"
        v-bind="attrs"
        v-on="on"
        depressed
        >
        <v-icon>
          mdi-currency-usd
        </v-icon>
      </v-btn>
    </template>

    <v-card
      class="d-flex flex-column justify-space-between" 
      height="calc(100vh - 102px)"
      >
      <v-card-title
        class="pa-0"
        >

        <div
          class="d-flex justify-space-between align-center fill-width mb-3"
          >
          <div
            class="fill-width"
            >
            <div
              class="white--text primary pa-4 fill-width d-flex align-center"
              >
              <div>
                Validación POs
              </div>
            </div>

            <div
              class="black--text px-4 mt-2 d-flex justify-space-between"
              >
              <div
                style="gap: 8px"
                class="d-flex"
                >
                <div
                  class="d-flex flex-column"
                  style="gap: 8px"
                  >
                  <v-chip
                    class="py-1"
                    dense
                    >
                    Sitio: {{ site.siteId }}
                  </v-chip>

                  <v-chip
                    class="py-1"
                    dense
                    >
                    Tarea: {{ rollout.id }}
                  </v-chip>
                </div>

                <div
                  class="d-flex flex-column"
                  style="gap: 8px"
                  >
                  <v-chip
                    class="py-1"
                    dense
                    >
                    Estado actual: {{ $t('status.rollout.' + rollout.status) }}
                  </v-chip>

                  <template
                    v-if="project.projectType == 'nokia'"
                    >
                    <v-chip
                      v-if="((rollout.additionals || []).length && !rollout.additionals[0].closed) || ((rollout.nokiaActivities || []).length && !rollout.nokiaActivities[0].closed)"
                      class="rounded-pill py-1 mb-2"
                      color="warning"
                      dense
                      >
                      Contiene tareas sin validar
                    </v-chip>
                  </template>

                  <template
                    v-else
                    >
                    <v-chip
                      v-if="((rollout.additionals || []).length && !rollout.additionals[0].closed) || ((rollout.huaweiActivities || []).length && !rollout.huaweiActivities[0].closed)"
                      class="rounded-pill py-1 mb-2"
                      color="warning"
                      dense
                      >
                      Contiene tareas sin validar
                    </v-chip>
                  </template>
                </div>
              </div>

              <div
                class="d-flex flex-row align-stretch"
                style="gap: 8px"
                >
                <div
                  class="d-flex flex-column align-stretch"
                  style="gap: 8px"
                  >
                  <v-chip
                    class="py-1"
                    dense
                    >
                    Total seleccionado CLF: {{ totalClfSelected }}
                  </v-chip>

                  <v-chip
                    class="py-1"
                    dense
                    >
                    Total seleccionado CLP: {{ totalClpSelected }}
                  </v-chip>
                </div>

                <div
                  class="d-flex flex-column align-stretch"
                  style="gap: 8px"
                  >
                  <v-chip
                    class="py-1"
                    dense
                    >
                    <span v-if="project.projectType == 'nokia'">
                      Nokia CLF: {{ totalClf }}
                    </span> 
                    <span
                      v-else
                      >
                      Huawei CLF: {{ totalClf }}
                    </span>
                  </v-chip>

                  <v-chip
                    class="py-1"
                    dense
                    >
                    <span v-if="project.projectType == 'nokia'">
                      Nokia CLP: {{ totalClp }}
                    </span> 
                    <span
                      v-else
                      >
                      Huawei CLP: {{ totalClp }}
                    </span>
                  </v-chip>
                </div>

                <div
                  class="d-flex flex-column align-stretch"
                  style="gap: 8px"
                  >
                  <v-chip
                    class="py-1"
                    dense
                    >
                    SVM CLF: {{ project.projectType == 'nokia' ? totalActivities : 0 }}
                  </v-chip>

                  <v-chip
                    class="py-1"
                    dense
                    >
                    SVM CLP: {{ project.projectType == 'nokia' ? totalAdditionals : totalActivities }}
                  </v-chip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card-title>

      <v-card-text
        class="px-1"
        >
        <activity
          class="mb-3"
          ref="activity"
          :rollout="rollout"
          @reload="reload"
          ></activity>

        <po
          v-if="project.projectType == 'nokia'"
          ref="po"
          :pos="pos"
          :full="billType == 'total'"
          @update="reload"
          ></po>

        <po-huawei
          v-else
          ref="po"
          :pos="pos"
          :full="billType == 'total'"
          @update="reload"
          ></po-huawei>
      </v-card-text>

      <v-card-actions
        class="flex-column align-end"
        >
        <div
          class="d-flex"
          style="gap: 8px"
          >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="dialog = false"
            >
            Cerrar
          </v-btn>

          <v-btn
            depressed
            color="primary"
            @click="changeType('partial')"
            class="rounded-lg"
            :disabled="!['pre_approved', 'partial', 'partial_billed'].includes(rollout.status)"
            >
            Fac. parcial
          </v-btn>

          <v-btn
            depressed
            color="primary"
            @click="changeType('total')"
            class="rounded-lg"
            :disabled="!['pre_approved', 'partial', 'partial_billed'].includes(rollout.status)"
            >
            Fac. total
          </v-btn>

          <v-btn
            v-if="false"
            depressed
            color="primary"
            @click="save"
            class="rounded-lg"
            >
            Guardar
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Po from './bills/Po'
import PoHuawei from './bills/HuaweiPo'
import Activity from './bills/Activity'

import { Bill } from '@/graphql/mutations/pos'
import { Activities, Additionals } from '@/utils/prices'
import { mapGetters } from 'vuex'

export default {
  data: () => ({
    dialog: false,
    billType: 'partial',
    totalClpSelected: 0,
    totalClfSelected: 0,
    activitiesPricing: Activities,
    additionalsPricing: Additionals
  }),

  props: {
    pos: {
      required: true,
      type: Array
    },
    rollout: {
      required: true,
      type: Object
    },
    site: {
      required: true,
      type: Object
    },
  },

  watch: {
    billType () {
      this.$nextTick ( () => {
        this.save()
      })
    }
  },

  computed: {
    ...mapGetters(['project']),

    totalClp () {
      var amount = 0

      if (this.pos && this.pos.length) {
        var pos = this.pos.filter ( v => v.totalValue.match(/CLP/) )

        pos.forEach ( po => {
          var value = po.totalValue.replace(' CLP', '')

          value = value.replaceAll('.', '')

          amount += parseFloat(value)
        })
      }

      return amount.toLocaleString('es-CL')
    },

    totalClf () {
      var amount = 0

      if (this.pos && this.pos.length) {
        var pos = this.pos.filter ( v => v.totalValue.match(/CLF/) )

        pos.forEach ( po => {
          var value = po.totalValue.replace(' CLF', '')
          value = value.replaceAll(',', '.')

          amount += parseFloat(value)
        })
      }

      return amount.toLocaleString('es-CL')
    },

    totalActivities () {
      var amount = 0

      if (this.project.projectType == 'nokia') {
        if (this.rollout.nokiaActivities && this.rollout.nokiaActivities.length) {
          this.rollout.nokiaActivities.forEach ( activity => {
            if (this.rollout.workType == 'TSS') {
              var value = this.activitiesPricing[activity.scene][activity.technology] * activity.quantity
            } else {
              var value = (activity.tss ? this.activitiesPricing[activity.scene]['tss'] : this.activitiesPricing[activity.scene]['default']) * activity.quantity
            }

            amount += parseFloat(value)
          })
        }
      } else {
        if (this.rollout.huaweiActivities && this.rollout.huaweiActivities.length) {
          this.rollout.huaweiActivities.forEach ( activity => {
            amount += parseFloat(activity.quantity * activity.price)
          })
        }
      }

      return amount.toLocaleString('es-CL')
    },

    totalAdditionals () {
      var amount = 0

      if (this.rollout.additionals && this.rollout.additionals.length) {
        this.rollout.additionals.forEach ( additional => {
          var value = additional.totalAmount || 0

          amount += parseFloat(value)
        })
      }

      return amount.toLocaleString('es-CL')
    },
  },

  methods: {
    save () {
      if (this.$refs.po.selected.length && this.$refs.po.$refs.form.validate()) {
        this.$apollo.mutate({
          mutation: Bill,
          variables: {
            input: {
              project: this.project.id,
              rolloutId: this.rollout.id,
              poIds: this.$refs.po.selected,
              activityIds: this.$refs.activity.activities,
              additionalIds: this.$refs.activity.additionals,
              billType: this.billType,
            }
          }
        }).then ( res => {
          this.$emit('reload', this.rollout.id)
          this.dialog = false
          this.$forceUpdate ()
        })
      }
    },

    changeType (billType) {
      if (this.billType === billType) {
        this.save ()
      } else {
        this.billType = billType
      }
    },

    reload () {
      var amount = 0

      if (this.pos && this.pos.length) {
        var pos = this.pos.filter( v => this.$refs.po.selected.includes(v.id) )
        var pos = pos.filter ( v => v.totalValue.match(/CLP/) )

        pos.forEach ( po => {
          var value = po.totalValue.replace(' CLP', '')
          value = value.replaceAll('.', '')

          amount += parseFloat(value)
        })
      }

      this.totalClpSelected = amount.toLocaleString('es-CL')

      var amount = 0

      if (this.pos && this.pos.length) {
        var pos = this.pos.filter( v => this.$refs.po.selected.includes(v.id) )
        var pos = pos.filter ( v => v.totalValue.match(/CLF/) )

        pos.forEach ( po => {
          var value = po.totalValue.replace(' CLF', '')
          value = value.replaceAll(',', '.')

          amount += parseFloat(value)
        })
      }

      this.totalClfSelected = amount.toLocaleString('es-CL')
    }
  },

  components: {
    Po, PoHuawei, Activity
  },
}
</script>
