<template>
  <v-card
    flat
    :color="(idx % 2) ? '' : 'grey lighten-2'"
    style="gap: 12px"
    class="px-3 py-2 d-flex flex-row justify-space-between rounded-lg"
    >
    <div
      >
      <div
        >
        <span class="font-weight-medium">Bodega:</span> {{ stock.stockLocation.name }}
      </div>

      <div
        >
        <span class="font-weight-medium">Tarea:</span> {{ stock.rolloutId }}
      </div>

      <div
        >
        <span class="font-weight-medium">Cantidad:</span> {{ stock.installedQuantity }}
      </div>
    </div>

    <div
      style="width: 120px"
      class="text-right grow"
      >
      <div>
        <span class="font-weight-medium">Cod. prod.:</span> {{ stock.item.sku }}
      </div>

      <div>
        {{ stock.item.description }}
      </div>

      <v-tooltip 
        color="primary"
        bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            v-on="on"
            v-bind="attrs"
            small
            class="font-weight-medium"
            :color="stock.lastMovement.approved ? 'primary' : 'orange'"
            >
            <v-icon
              small
              class="mr-1"
              >{{ stock.lastMovement.approved ? 'mdi-check-bold' : 'mdi-reload' }}</v-icon> {{ stock.lastMovement.movementType }}
          </v-chip>
        </template>
        <span>{{ stock.lastMovement.approved ? 'Despachado' : 'Pendiente despacho' }}</span>
      </v-tooltip>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    stock: {
      type: Object,
      required: true
    },

    idx: {
      type: Number,
      required: true
    }
  }
}
</script>
