<template>
  <div
    class="text--primary"
    >
    <v-snackbar
      v-model="alert.active"
      :color="alert.type"
      >
      {{ alert.message }}
    </v-snackbar>
    <div
      style="max-width: 150px; border-bottom: 1px solid white"
      class="d-flex align-center primary lighten-2 py-2 px-4 rounded-t-lg white--text"
      >
      POs
    </div>
    <po-header
      ></po-header>
    <div
      style="gap: 12px; border-bottom: 1px solid grey; border-left: 1px solid grey; border-right: 1px solid grey; cursor: pointer"
      class="text-center pa-2 font-weight-medium primary lighten-3 white--text"
      @click="showMain = !showMain"
      >
      POs
    </div>
    <v-expand-transition>
      <div
        v-if="showMain"
        >
        <v-form
          v-model="valid"
          ref="form"
          lazy-validation
          >
          <template
            v-for="po, i in pos"
            >
            <div
              style="gap: 12px; border-bottom: 1px solid grey; border-left: 1px solid grey; border-right: 1px solid grey; background-color: #e4e4e4; cursor: pointer"
              class="d-flex align-center px-2"
              >
              <div
                class="shrink"
                >
                <v-checkbox 
                  class="mt-n1"
                  hide-details
                  v-model="selected"
                  :value="po.id"
                  :disabled="po.checked || full" 
                  />
              </div>
              <div
                class="grow py-3"
                @click.stop="showDetails(i)"
                >
                <v-row
                  class="align-stretch"
                  >
                  <v-col
                    cols="1"
                    class="d-flex align-center justify-center"
                    style="border-right: 1px solid grey;"
                    >
                    {{ po.number }}
                  </v-col>

                  <v-col
                    cols="3"
                    class="d-flex align-center justify-center text-center"
                    style="border-right: 1px solid grey;"
                    >
                  </v-col>

                  <v-col
                    cols="1"
                    class="d-flex align-center justify-center"
                    style="border-right: 1px solid grey;"
                    >
                  </v-col>

                  <v-col
                    cols="1"
                    class="d-flex align-center justify-center"
                    style="border-right: 1px solid grey;"
                    >
                    <v-text-field
                      v-model="idoc[i]"
                      label="IDOC"
                      hide-details
                      dense
                      solo
                      outlined
                      flat
                      :disabled="!!po.billedAt || po.checked"
                      :rules="[
                        v => (!!findSelected(po.id) && !!v) || !findSelected(po.id) || 'Campo obligatorio'
                      ]"
                      @click.stop=""
                      @change="edit(i)"
                      ></v-text-field>
                  </v-col>

                  <v-col
                    cols="1"
                    class="d-flex align-center justify-center"
                    style="border-right: 1px solid grey;"
                    >
                  </v-col>

                  <v-col
                    cols="1"
                    class="d-flex align-center justify-center"
                    style="border-right: 1px solid grey;"
                    >
                    {{ po.totalValue }}
                  </v-col>

                  <v-col
                    cols="4"
                    >
                    <v-tooltip 
                      color="primary"
                      bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 500px"
                          v-bind="attrs"
                          v-on="on"
                          >
                          {{ po.freeText }}
                        </div>
                      </template>
                      <span
                        style="max-width: 300px"
                        >
                        {{ po.freeText }}
                      </span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </div>
            </div>

            <v-expand-transition>
              <po-card
                v-if="show[i]"
                :key="show[i]"
                :po="po"
                ></po-card>
            </v-expand-transition>
          </template>
        </v-form>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import { EditPo } from '@/graphql/mutations/pos' 
import PoHeader from './PoHeader'
import PoCard from './PoCard'

export default {
  data: () => ({
    valid: true,
    alert: {
      active: false,
      message: null,
      type: ''
    },
    showMain: true,
    selected: [],
    show: [],
    idoc: []
  }),

  props: {
    pos: {
      required: true,
      type: Array
    },

    full: {
      required: false,
      type: Boolean,
      default: false
    }
  },

  watch: {
    valid (val) {
      if (!val) {
        this.alert = {
          active: true,
          message: 'IDOC obligatorio',
          type: 'error'
        }
      }
    },

    full (val) {
      if (val) {
        this.pos.forEach ( po => {
          this.selected.push(po.id)
        })
      } else {
        this.pos.forEach ( po => {
          if (!po.checked) {
            var index = this.selected.indexOf(po.id)
            this.selected.splice(index, 1)
          }
        })
      }
    },

    selected (val) {
      this.$emit('update')
      if (!val) {
        this.$refs.form.resetValidation()
      } else {
        this.$refs.form.validate()
      }
    }
  },

  created () {
    this.pos.forEach ( po => {
      if (po.checked || this.full) {
        this.selected.push(po.id)
      }

      this.pos.forEach ( (po, i) => {
        this.idoc[i] = po.idoc
      })
    })
  },

  methods: {
    showDetails (idx) {
      if (this.show[idx]) {
        this.show[idx] = false
      } else {
        this.show[idx] = true
      }

      this.$forceUpdate()
    },

    edit (i) {
      this.$apollo.mutate({
        mutation: EditPo,
        variables: {
          input: {
            id: this.pos[i].id,
            attributes: {
              idoc: this.idoc[i]
            }
          }
        }
      }).then ( res => {
        this.alert = {
          active: true,
          message: '¡IDOC actualizado con éxito!',
          type: 'success'
        }
        this.$emit('reload')
      }).catch ( err => {
        this.alert = {
          active: true,
          message: 'IDOC duplicado',
          type: 'error'
        }
      })
    },

    findSelected (id) {
      if (this.selected.indexOf(id) >= 0) return true
      return false
    }
  },

  components: {
    PoHeader, PoCard
  }
}
</script>
