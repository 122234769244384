<template>
  <div
    class="pa-3 fill-height d-flex flex-column align-stretch"
    >
    <edit
      list
      v-if="selected"
      :key="selected.id"
      :rollout="selected"
      ref="edit"
      @reload="fetchRollouts"
      ></edit>

    <template
      v-if="$route.params.id == 'nokia'"
      >
      <task
        list
        v-if="selected"
        :key="selected.id"
        :rollout="selected"
        ref="task"
        @reload="fetchRollouts"
        ></task>
    </template>

    <template
      v-else
      >
      <huawei-task
        list
        v-if="selected"
        :key="selected.id"
        :rollout="selected"
        ref="task"
        @reload="fetchRollouts"
        ></huawei-task>
    </template>

    <additional
      list
      v-if="selected"
      :key="selected.id"
      :rollout="selected"
      ref="additional"
      @reload="fetchRollouts"
      ></additional>

    <close
      list
      ref="close"
      v-if="selected"
      :rollout="selected"
      :activity="activity"
      :disabled="false"
      :id="selected.id"
      @reload="fetchRollouts"
      ></close>

    <v-card
      class="mb-2"
      rounded="lg"
      flat
      >
      <v-card-text
        class="d-flex pa-2 align-center"
        style="gap: 8px"
        >
        <v-spacer></v-spacer>

        <v-dialog
          v-model="dialog"
          width="800"
          content-class="rounded-lg"
          >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed 
              text
              color="primary"
              v-bind="attrs"
              v-on="on"
              >
              <v-icon small class="mr-1" style="margin-top: -2px">mdi-filter</v-icon> Filtrar
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              Filtrar tabla
            </v-card-title>

            <v-card-text>
              <v-row>
                <template
                  v-for="field in ['id', 'name', 'region']"
                  >
                  <v-col
                    cols="6"
                    >
                    <v-text-field
                      v-model="filters[field]"
                      outlined
                      flat
                      dense
                      hide-details
                      :label="$t('fields.rollouts.' + field)"
                      ></v-text-field>
                  </v-col>
                </template>

                <v-col
                  cols="6"
                  >
                  <v-autocomplete
                    v-model="filters.technology"
                    outlined
                    flat
                    dense
                    hide-details
                    clearable
                    :items="['4G', '5G']"
                    label="Tecnología"
                    ></v-autocomplete>
                </v-col>

                <v-col
                  cols="6"
                  >
                  <v-autocomplete
                    v-model="filters.workType"
                    outlined
                    flat
                    dense
                    hide-details
                    clearable
                    :items="['TSS', 'Implementación']"
                    label="Tipo de trabajo"
                    ></v-autocomplete>
                </v-col>

                <v-col
                  cols="6"
                  >
                  <v-autocomplete
                    v-model="filters.status"
                    clearable
                    outlined
                    flat
                    dense
                    hide-details
                    :items="state"
                    single-line
                    label="Status"
                    multiple
                    >
                    <template slot="item" slot-scope="data">
                      {{ $t('status.rollout.' + data.item) }}
                    </template>

                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0">
                        <span>
                          {{ $t('status.rollout.' + item) }}
                        </span>
                      </v-chip>
                      <span
                        v-if="index === 1"
                        class="grey--text text-caption"
                        >
                        (+{{ filters.status.length - 1 }})
                      </span>
                    </template>
                  </v-autocomplete>
                </v-col>

                <template
                  v-for="field, i in fields"
                  >
                  <v-col
                    cols="12"
                    md="6"
                    >
                    <v-dialog
                      ref="dialog"
                      v-model="modal[i]"
                      :return-value.sync="filters[field]"
                      width="290px"
                      >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          :label="$t('fields.rollout.' + field)"
                          v-model="filters[field]"
                          hide-details
                          dense
                          range
                          readonly
                          style="background-color: white"
                          v-bind="attrs"
                          v-on="on"
                          ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="filters[field]"
                        scrollable
                        range
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                          small
                          text color="primary" @click="$refs.dialog[i].save([])">
                          Limpiar
                        </v-btn>
                        <v-btn
                          text
                          small
                          color="primary"
                          @click="modal.splice(i, 1)"
                          >
                          Cancelar
                        </v-btn>
                        <v-btn
                          text
                          small
                          color="primary"
                          @click="$refs.dialog[i].save(filters[field])"
                          >
                          Aceptar
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                </template>

                <template
                  v-for="field in ['additionals', 'activities', 'validatedAdditionals', 'validatedActivities']"
                  >
                  <v-col
                    cols="6"
                    >
                    <v-autocomplete
                      v-model="filters[field]"
                      outlined
                      flat
                      dense
                      hide-details
                      :items="triary"
                      :label="$t('fields.rollouts.' + field)"
                      ></v-autocomplete>
                  </v-col>
                </template>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions
              class="px-6"
              >
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="clean"
                >
                Limpiar filtros
              </v-btn>

              <v-btn
                color="primary"
                @click="perform"
                >
                Aplicar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-autocomplete
          v-model="siteId"
          style="max-width: 300px"
          :items="sites"
          clearable
          item-text="siteId"
          item-value="id"
          solo
          outlined
          flat
          dense
          hide-details
          label="Buscar sitio"
          ></v-autocomplete>
      </v-card-text>
    </v-card>

    <v-simple-table
      height="calc(100vh - 300px)"
      width="100%"
      style="background-color: transparent"
      id="custom-table"
      >
      <template v-slot:default>
        <thead
          >
          <tr>
            <th class="primary text-left white--text">
              ID
            </th>
            <th class="primary text-left white--text">
              Site ID
            </th>
            <th class="primary text-left white--text">
              Nombre
            </th>
            <th class="primary text-left white--text">
              Región
            </th>
            <th class="primary text-left white--text">
              Tipo trabajo
            </th>
            <th class="primary text-left white--text">
              Tecnología
            </th>
            <th class="primary text-left white--text">
              Adicionales
            </th>
            <th class="primary text-left white--text">
              Ad. validados
            </th>
            <th class="primary text-left white--text">
              Act. validadas
            </th>
            <th class="primary text-left white--text">
              Fecha asignación
            </th>
            <th class="primary text-left white--text">
              Fecha inicio
            </th>
            <th class="primary text-left white--text">
              Fecha fin
            </th>
            <th class="primary text-left white--text">
              Fecha envío doc.
            </th>
            <th class="primary text-left white--text">
              Status
            </th>
            <th class="primary text-left white--text">
              Observaciones
            </th>
          </tr>
        </thead>
        <tbody
          >
          <tr
            v-for="rollout, i in rollouts"
            :key="rollout.id"
            :style="(i % 2) ? 'background-color: #e4e4e4' : 'background-color: white'"
            >
            <th
              class="font-weight-regular text--primary text-body-1"
              :style="(i % 2) ? 'background-color: #e4e4e4' : 'background-color: white'"
              style="cursor: pointer"
              @click.stop="$router.push({ name: 'rollout', params: { id: $route.params.id }, query: { site_id: rollout.site.id, task_id: rollout.id } })"
              >
              {{ rollout.id }}
            </th>
            <td
              style="cursor: pointer"
              @click.stop="$router.push({ name: 'rollout', params: { id: $route.params.id }, query: { site_id: rollout.site.id, task_id: rollout.id } })"
              >
              {{ rollout.site.siteId }}
            </td>
            <td
              style="cursor: pointer"
              @click.stop="$router.push({ name: 'rollout', params: { id: $route.params.id }, query: { site_id: rollout.site.id, task_id: rollout.id } })"
              >
              <span
                class="description-box"
                >
                {{ rollout.site.name }}
              </span>
            </td>
            <td
              style="cursor: pointer"
              @click.stop="$router.push({ name: 'rollout', params: { id: $route.params.id }, query: { site_id: rollout.site.id, task_id: rollout.id } })"
              >{{ rollout.site.region }}</td>
            <td
              style="cursor: pointer"
              @click.stop="editTask(rollout)"
              >
              {{ rollout.workType || 'Sin asignar' }}
            </td>
            <td
              style="cursor: pointer"
              @click.stop="editTask(rollout)"
              >
              {{ rollout.technology.join(' / ') }}
            </td>
            <td
              style="cursor: pointer"
              @click.stop="editAdditional(rollout)"
              >
              {{ rollout.additionals.length ? 'Sí' : 'No' }}
            </td>
            <td
              style="cursor: pointer"
              @click.stop="closeTask(rollout, 'additional')"
              >
              {{ (rollout.additionals[0] || {}).closed ? 'Sí' : 'No' }}
            </td>
            <td
              v-if="project.projectType == 'nokia'"
              style="cursor: pointer"
              @click.stop="closeTask(rollout, 'nokia')"
              >
              {{ (rollout.nokiaActivities[0] || {}).closed ? 'Sí' : 'No' }}
            </td>
            <td
              v-if="project.projectType == 'huawei'"
              style="cursor: pointer"
              @click.stop="closeTask(rollout, 'huawei')"
              >
              {{ (rollout.huaweiActivities[0] || {}).closed ? 'Sí' : 'No' }}
            </td>
            <td
              style="cursor: pointer; min-width: 110px"
              @click="editRollout(rollout)"
              >
              {{ rollout.assignationDate }}
            </td>
            <td
              style="cursor: pointer; min-width: 110px"
              @click="editRollout(rollout)"
              >
              {{ rollout.startAt }}
            </td>
            <td
              style="cursor: pointer; min-width: 110px"
              @click="editRollout(rollout)"
              >
              {{ rollout.endsAt }}
            </td>
            <td
              style="cursor: pointer; min-width: 110px"
              @click="editRollout(rollout)"
              >
              {{ rollout.documentationSentAt }}
            </td>
            <td
              style="cursor: pointer"
              @click="editRollout(rollout)"
              >
              <span
                class="description-box"
                >
                {{ $t('status.rollout.' + rollout.status) }}
              </span>
            </td>
            <td
              style="cursor: pointer; max-width: 300px"
              @click="editRollout(rollout)"
              >
              <span
                class="description-box"
                >
                {{ rollout.obs }}
              </span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <div
      class="mt-3 mx-auto"
      style="max-width: 400px"
      >
      <v-pagination
        v-if="pages > 1"
        :key="pages"
        v-model="page"
        :length="pages"
        ></v-pagination>

      <v-select
        v-model="per"
        style="max-width: 100px"
        class="mx-auto mt-3"
        :items="[10, 15, 20, 30, 50]"
        outlined
        solo
        flat
        ></v-select>
    </div>
  </div>
</template>

<script>
import Edit from '@/components/sites/main/rollout/Edit'
import Task from '@/components/sites/main/tasks/Done'
import HuaweiTask from '@/components/sites/main/activities/huawei/Dialog'
import Additional from '@/components/sites/main/misc/Done'
import Close from '@/components/sites/main/activities/Close'

import { RolloutsList } from '@/graphql/queries/rollouts'
import { Sites } from '@/graphql/queries/sites'
import { mapGetters } from 'vuex'

export default {
  data: () => ({
    rollouts: [],
    page: 1,
    dialog: false,
    per: 20,
    pages: 0,
    selected: null,
    activity: null,
    band: 0,
    siteId: null,
    filters: {
      id: null,
      name: null,
      region: null,
      workType: null,
      technology: null,
      additionals: null,
      activities: null,
      validatedAdditionals: null,
      validatedActivities: null,
      assignationDate: null,
      startAt: null,
      endsAt: null,
      documentationSentAt: null,
      status: ['assigned', 'installing', 'installed', 'pre_approved', 'approved', 'partial', 'partial_billed', 'total'],
    },
    sites: [],
    triary: [
      {
        text: 'Todos',
        value: null,
      },
      {
        text: 'Sí',
        value: true,
      },
      {
        text: 'No',
        value: false,
      },
    ],
    modal: [],
    fields: [
      'assignationDate',
      'startAt',
      'endsAt',
      'documentationSentAt',
    ],
    state: ['assigned', 'installing', 'installed', 'pre_approved', 'approved', 'partial', 'partial_billed', 'total'],
  }),

  created () {
    this.fetchRollouts ()
    this.fetchSites ()
  },

  computed: {
    ...mapGetters(['currentUser', 'project'])
  },

  watch: {
    page () {
      this.fetchRollouts ()
    },

    per () {
      this.fetchRollouts ()
    },

    siteId (val) {
      this.$router.push({ name: 'rollouts', query: { site_id: val, t: Date.now() } })
      this.fetchRollouts ()
    }
  },

  methods: {
    fetchSites () {
      this.$apollo.query({
        query: Sites,
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.sites = res.data.sites
      })
    },

    fetchRollouts () {
      this.$apollo.query({
        query: RolloutsList,
        variables: {
          projectId: this.$route.params.id,
          state: this.state,
          page: this.page,
          per: this.per,
          siteId: this.siteId,
          filters: this.filters
        },
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.rollouts = res.data.rolloutsList.rollouts
        this.pages = res.data.rolloutsList.pages
        this.band += 1
        this.$forceUpdate()
      })
    },

    updateState (state) {
      this.state = state

      this.$nextTick ( () => {
        this.fetchRollouts ()
      })
    },

    editRollout (rollout) {
      this.selected = rollout

      this.$nextTick ( () => {
        this.$refs.edit.dialog = true
      })
    },

    editTask (rollout) {
      if ((['shibui', 'rollout', 'management'].includes(this.currentUser.currentRoleName))) {
        this.selected = rollout

        this.$nextTick ( () => {
          this.$refs.task.dialog = true
        })
      }
    },

    editAdditional (rollout) {
      if ((['shibui', 'rollout', 'management'].includes(this.currentUser.currentRoleName))) {
        this.selected = rollout

        this.$nextTick ( () => {
          this.$refs.additional.dialog = true
        })
      }
    },

    closeTask (rollout, activity) {
      this.selected = rollout
      this.activity = activity

      if (activity == 'additional') {
        if ((['shibui', 'rollout', 'management'].includes(this.currentUser.currentRoleName)) && rollout.additionals.length && !rollout.additionals[0].closed) {
          this.$nextTick ( () => {
            this.$refs.close.dialog = true
          })
        }
      } else if (activity == 'huawei') {
        if ((['shibui', 'rollout', 'management'].includes(this.currentUser.currentRoleName)) && rollout.huaweiActivities.length && !rollout.huaweiActivities[0].closed) {
          this.$nextTick ( () => {
            this.$refs.close.dialog = true
          })
        }
      } else {
        if ((['shibui', 'rollout', 'management'].includes(this.currentUser.currentRoleName)) && rollout.nokiaActivities.length && !rollout.nokiaActivities[0].closed) {
          this.$nextTick ( () => {
            this.$refs.close.dialog = true
          })
        }
      }
    },

    perform () {
      this.dialog = false
      this.page = 1
      this.fetchRollouts ()
    },

    clean () {
      this.filters = {
        id: null,
        name: null,
        region: null,
        workType: null,
        technology: null,
        additionals: null,
        activities: null,
        validatedAdditionals: null,
        validatedActivities: null,
        assignationDate: null,
        startAt: null,
        endsAt: null,
        documentationSentAt: null,
        status: ['assigned', 'installing', 'installed', 'pre_approved', 'approved', 'partial', 'partial_billed', 'total'],
      }
    }
  },

  components: { Edit, Task, Additional, Close, HuaweiTask }
}
</script>

<style>
#custom-table > div > table thead th {
  position: sticky;
  top: 0;
  z-index: 1;
  background: white;
}

#custom-table > div > table tbody th {
  position: relative;
}

#custom-table > div > table thead th:first-child {
  position: sticky;
  left: 0;
  z-index: 100;
}

#custom-table > div > table thead th:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
}

#custom-table > div > table tbody th {
  position: sticky;
  left: 0;
  z-index: 1;
}
</style>
