<template>
  <div>
    <v-snackbar
      v-model="success"
      color="success"
      >
      <div
        class="text-center font-weight-bold"
        >
        ¡Sitio creado con éxito!
      </div>
    </v-snackbar>
    <v-card
      rounded="lg"
      color="transparent"
      >
      <v-card-title
        class="py-1 primary white--text lower-opacity"
        >
        Datos del sitio
        <v-spacer></v-spacer>

        <v-autocomplete
          dense
          solo
          flat
          class="rounded-lg primary ml-3"
          style="max-width: 300px"
          label="Buscar sitio"
          prepend-inner-icon="mdi-magnify"
          :items="sites"
          item-text="siteId"
          item-value="id"
          v-model="search"
          hide-details
          ></v-autocomplete>
      </v-card-title>

      <v-card-text
        class="pt-3 lower-background-opacity"
        >
        <v-row
          :key="site.id"
          v-if="site"
          align="end"
          >
          <v-col
            lg="11"
            md="10"
            class="pr-0"
            >
            <v-row>
              <template
                v-for="header in headers"
                >
                <v-col
                  :cols="['lng', 'lat', 'height', 'siteId'].includes(header) ? 
                    1 : 
                    (['structureType', 'structureCode', 'towerOwner', 'chief', 'region', 'neighbourhood', 'address', 'name'].includes(header) ?
                      2 :
                      (header == 'obs' ? 4 : 3))"
                  class="pr-0"
                  >
                  <v-dialog
                    v-if="header == 'obs'"
                    v-model="dialog"
                    width="1200"
                    >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        :label="$t('fields.site.' + header)"
                        :value="site[header]"
                        readonly
                        hide-details
                        rows="1"
                        dense
                        style="background-color: white"
                        v-on="on"
                        v-bind="attrs"
                        ></v-text-field>
                    </template>

                    <v-card>
                      <v-card-title
                        class="mb-3"
                        >
                        Observaciones
                      </v-card-title>

                      <v-card-text>
                        <v-textarea
                          outlined
                          :label="$t('fields.site.' + header)"
                          :value="site[header]"
                          readonly
                          hide-details
                          rows="3"
                          auto-grow
                          dense
                          style="background-color: white"
                          ></v-textarea>
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          text
                          @click="dialog = false"
                          >
                          Cerrar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-text-field
                    v-else
                    outlined
                    :label="$t('fields.site.' + header)"
                    :value="site[header]"
                    readonly
                    hide-details
                    dense
                    style="background-color: white"
                    ></v-text-field>
                </v-col>
              </template>
            </v-row>
          </v-col>

          <v-col
            lg="1"
            md="2"
            style="gap: 12px"
            class="d-flex justify-end"
            >
            <edit
              @reload="$emit('search', search)"
              :site="site"
              ></edit>

            <create
              @reload="newSite"
              ></create>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { Sites } from '@/graphql/queries/sites'

const Edit = () => import('./Edit')
const Create = () => import('./Create')

export default {
  data: () => ({
    headers: ["siteId", "name", "address", "height", "lng", "lat", "region", "neighbourhood", "chief", "structureType", "structureCode", "towerOwner", "obs"],
    sites: [],
    search: null,
    success: false,
    dialog: false
  }),

  props: {
    site: {
      type: Object,
      required: false
    }
  },

  components: { Edit, Create },

  created () {
    this.fetchSites ()

    if (!!this.$route.query.site_id) {
      this.$emit('search', this.$route.query.site_id)
    }
  },

  watch: {
    search (val) {
      this.$router.push({ name: 'rollout', query: { site_id: val, t: Date.now() } })
    },

    '$route.query.site_id' (val) {
      this.$emit('search', val)
    }
  },

  methods: {
    fetchSites () {
      this.$apollo.query({
        query: Sites,
        variables: {
          projectId: this.$route.params.id
        },
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.sites = res.data.sites

        if (this.sites.length && !this.$route.query.site_id) {
          this.$router.push({ name: 'rollout', query: { site_id: this.sites[0].id, t: Date.now() } })
        }
      })
    },

    newSite (id) {
      this.fetchSites ()
      this.search = id
      this.success = true
    }
  }
}
</script>
