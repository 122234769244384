<template>
  <div>
    <v-snackbar
      :color="alert.type"
      v-model="alert.active"
      >
      <div>
        <template
          v-for="message in alert.messages"
          >
          {{ message }}
          <br />
        </template>
      </div>
    </v-snackbar>
    <v-card
      outlined
      tile
      :ripple="false"
      @click="expand = !expand"
      >
      <v-card-text>
        <v-row
          class="align-center"
          >
          <v-col
            order="0"
            cols="4"
            class="d-flex align-center"
            >
            <div
              v-if="['shibui', 'management'].includes(currentUser.currentRoleName) && deleting && !po.billedAt"
              >
              <v-checkbox
                class="pr-2"
                :value="check"
                @click.stop="check = !check"
                ></v-checkbox>
            </div>

            <div
              @click.stop="openFile"
              >
              <div
                class="caption"
                >
                Número
              </div>

              <div
                class="primary--text font-weight-medium"
                >
                {{ po.number || (po.poNumber + '-' + po.lineNumber) }}
              </div>
            </div>
          </v-col>

          <v-col
            order="2"
            cols="4"
            class="text-center"
            >
            <div
              class="caption"
              >
              Fecha de facturación
            </div>

            <div
              class="primary--text font-weight-medium"
              >
              {{ po.billedAt || 'Sin facturar' }}
            </div>
          </v-col>

          <v-col
            order="1"
            v-if="project.projectType == 'huawei'"
            cols="4"
            class="text-center"
            >
            <div
              class="caption"
              >
              Número de linea
            </div>

            <div
              class="primary--text font-weight-medium"
              >
              {{ po.lineNumber }}
            </div>
          </v-col>

          <v-col
            order="3"
            v-else
            cols="4"
            class="text-right"
            >
            <v-icon>
              mdi-chevron-{{ expand ? 'up' : 'down' }}
            </v-icon>
          </v-col>
        </v-row>

        <v-expand-transition>
          <div
            v-if="expand"
            >
            <v-divider
              class="my-2"
              ></v-divider>

            <v-row
              >
              <v-col
                cols="4"
                class="text-center"
                >
                <div
                  class="caption font-weight-medium"
                  >
                  <template
                    v-if="project.projectType == 'huawei'"
                    >
                    Código de proyecto
                  </template>
                  <template
                    v-else
                    >
                    Supplier Name
                  </template>
                </div>

                <div
                  class="caption"
                  >
                  <template
                    v-if="project.projecType == 'huawei'"
                    >
                    {{ po.projectCode }}
                  </template>
                  <template
                    v-else
                    >
                    {{ po.supplierName }}
                  </template>
                </div>
              </v-col>

              <v-col
                cols="4"
                class="text-center"
                >
                <template
                  v-if="project.projectType == 'nokia'"
                  >
                  <div
                    class="caption font-weight-medium"
                    >
                    Delivery Address
                  </div>

                  <div
                    class="caption"
                    >
                    {{ po.deliveryAddress }}
                  </div>
                </template>
              </v-col>

              <v-col
                cols="4"
                class="text-center"
                >
                <div
                  class="caption font-weight-medium"
                  >
                  Total Value
                </div>

                <div
                  class="caption"
                  >
                  {{ po.totalValue }}
                </div>
              </v-col>
            </v-row>

            <v-row
              v-if="rollout && po.checked"
              @click.stop=""
              >
              <v-col
                cols="6"
                >
                <v-text-field
                  outlined
                  dense
                  flat
                  hide-details
                  :disabled="po.closed"
                  type="number"
                  label="Monto"
                  v-model="attributes.amount"
                  @input="save"
                  ></v-text-field>
              </v-col>
              <v-col
                cols="6"
                >
                <v-text-field
                  outlined
                  dense
                  flat
                  hide-details
                  :disabled="po.closed"
                  type="number"
                  label="Número de factura"
                  v-model="attributes.billNumber"
                  @input="save"
                  ></v-text-field>
              </v-col>
              <v-col
                cols="6"
                >
                <v-text-field
                  outlined
                  dense
                  flat
                  hide-details
                  :disabled="po.closed"
                  type="number"
                  label="Aprobación"
                  v-model="attributes.approvalCode"
                  @input="save"
                  ></v-text-field>
              </v-col>
              <v-col
                cols="6"
                >
                <v-dialog
                  ref="dialog"
                  v-model="dialog"
                  :return-value.sync="attributes.billedAt"
                  width="290px"
                  >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      label="Fecha de facturación"
                      v-model="attributes.billedAt"
                      hide-details
                      dense
                      :disabled="po.closed"
                      class="mb-3"
                      @click.stop="dialog = true"
                      ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="attributes.billedAt"
                    @change="save"
                    scrollable
                    >
                    <!--
                      :max="new Date().toISOString().slice(0,10)"
                      :min="prevDate(field, i)"
                    -->
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="dialog = false"
                      >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(attributes.billedAt)"
                      >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
          </div>
        </v-expand-transition>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { EditPo, EditHuaweiPo } from '@/graphql/mutations/pos'

export default {
  data: () => ({
    alert: {
      active: false,
      messages: [],
      type: null
    },
    expand: false,
    check: false,
    dialog: false,
    timer: null,
    attributes: {
      billNumber: null,
      billedAt: null,
      approvalCode: null,
      amount: null
    }
  }),

  watch: {
    check (val) {
      this.$emit('deleting', this.po.id)
    }
  },

  computed: {
    ...mapGetters(['currentUser', 'project'])
  },

  created () {
    this.attributes.billedAt = this.po.billedAt
    this.attributes.billNumber = this.po.billNumber
    this.attributes.approvalCode = this.po.approvalCode
    this.attributes.amount = this.po.amount
  },

  props: {
    project: {
      required: false,
      type: Object
    },

    po: {
      required: true,
      type: Object
    },

    deleting: {
      required: false,
      type: Boolean,
      default: false
    },

    rollout: {
      required: false,
      type: Object
    }
  },


  methods: {
    save () {
      if (this.timer) clearTimeout(this.timer)

      this.timer = setTimeout( () => {
        this.$apollo.mutate({
          mutation: this.project.projecType == 'nokia' ? EditPo : EditHuaweiPo,
          variables: {
            input: {
              id: this.po.id,
              attributes: {
                ...this.attributes
              }
            }
          }
        }).then ( res => {
          this.$emit('reload')
          this.alert = {
            active: true,
            messages: ['Actualización realizada con éxito.'],
            type: 'success'
          }
        }).catch ( err => {
          let messages = []

          err.graphQLErrors.forEach( error => {
            messages.push(this.$t(`errors.${error.extensions.field}_${error.extensions.message}`))
          })

          this.alert = {
            active: true,
            messages: messages,
            type: 'error'
          }
        })
      }, 1000)
    },

    openFile () {
      window.open(`${process.env.VUE_APP_RAILS_URL}/get_po_file/${this.po.number}`)
    }
  }
}
</script>
