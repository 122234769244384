<template>
  <div
    v-if="rollout.nokiaActivities.length || rollout.additionals.length || rollout.huaweiActivities.length"
    class="text--primary"
    >
    <v-snackbar
      v-model="success"
      color="success"
      >
      Observaciones actualizadas con éxito.
    </v-snackbar>

    <div
      style="max-width: 150px; border-bottom: 1px solid white"
      class="d-flex align-center primary lighten-2 py-2 px-4 rounded-t-lg white--text"
      >
      Tareas
    </div>
    <activity-header
      @selectAll="selectAll"
      ></activity-header>
    <div
      style="gap: 12px; border-bottom: 1px solid grey; border-left: 1px solid grey; border-right: 1px solid grey; cursor: pointer"
      class="text-center pa-2 font-weight-medium primary lighten-3 white--text"
      @click="showActivities = !showActivities"
      >
      Actividades
    </div>
    <v-expand-transition>
      <div
        v-if="showActivities"
        >
        <template
          v-if="project.projectType == 'nokia'"
          v-for="activity, i in rollout.nokiaActivities"
          >
          <div
            :style="activities[i] ? 'background-color: #e4e4e4' : ''"
            style="gap: 12px; border-bottom: 1px solid grey; border-left: 1px solid grey; border-right: 1px solid grey"
            class="d-flex align-center px-2"
            >
            <div
              class="shrink"
              >
              <v-checkbox 
                class="mt-n1"
                hide-details
                v-model="activities[i]"
                :disabled="activity.checked"
                :value="activity.id"
                />
            </div>
            <div
              class="grow py-3"
              >
              <v-row
                class="align-stretch"
                >
                <v-col
                  cols="1"
                  class="d-flex align-center justify-center"
                  style="border-right: 1px solid grey;"
                  >
                  Escenario:
                </v-col>

                <v-col
                  cols="3"
                  style="border-right: 1px solid grey; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                  class="d-flex align-center"
                  >
                  <v-tooltip 
                    color="primary"
                    bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                        >
                        {{ activity.scene }}
                      </span>
                    </template>
                    <span>
                      {{ activity.scene }}
                    </span>
                  </v-tooltip>
                </v-col>

                <v-col
                  cols="1"
                  class="d-flex align-center justify-center"
                  style="border-right: 1px solid grey;"
                  >
                  {{ activity.quantity }}
                </v-col>

                <v-col
                  cols="1"
                  class="d-flex align-center justify-center"
                  style="border-right: 1px solid grey;"
                  >
                  {{ activity.technology }}
                </v-col>

                <v-col
                  cols="1"
                  class="d-flex align-center justify-center"
                  style="border-right: 1px solid grey;"
                  >

                  <template
                    >
                    {{ activity.amount.toLocaleString('es-CL') }}
                  </template>
                </v-col>

                <v-col
                  cols="1"
                  class="d-flex align-center justify-center"
                  style="border-right: 1px solid grey;"
                  >
                  <template
                    >
                    {{ (activity.amount * (activity.quantity || 1)).toLocaleString('es-CL') }}
                  </template>
                </v-col>

                <v-col
                  cols="4"
                  class="d-flex align-center justify-center text-center"
                  >
                  <v-text-field
                    v-model="activity.obs"
                    outlined
                    flat
                    solo
                    label="Observaciones"
                    hide-details
                    dense
                    @blur="editActivity(activity.id, activity.obs)"
                    ></v-text-field>
                </v-col>
              </v-row>
            </div>
          </div>
        </template>

        <template
          v-if="project.projectType == 'huawei'"
          v-for="(activity, i) in rollout.huaweiActivities"
          >
          <div
            :style="activities[i] ? 'background-color: #e4e4e4' : ''"
            style="gap: 12px; border-bottom: 1px solid grey; border-left: 1px solid grey; border-right: 1px solid grey"
            class="d-flex align-center px-2"
            >
            <div
              class="shrink"
              >
              <v-checkbox 
                class="mt-n1"
                hide-details
                v-model="activities[i]"
                :disabled="activity.checked"
                :value="activity.id"
                />
            </div>
            <div
              class="grow py-3"
              >
              <v-row
                class="align-stretch"
                >
                <v-col
                  cols="1"
                  class="d-flex align-center justify-center"
                  style="border-right: 1px solid grey;"
                  >
                </v-col>

                <v-col
                  cols="4"
                  :class="activity.linkId ? 'success lighten-4' : 'error lighten-4'"
                  style="border-right: 1px solid grey; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                  class="d-flex align-center"
                  >
                  <v-tooltip 
                    color="primary"
                    bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                        >
                        {{ activity.description }}
                      </span>
                    </template>
                    <span>
                      {{ activity.description }}
                    </span>
                  </v-tooltip>
                </v-col>

                <v-col
                  cols="1"
                  class="d-flex align-center justify-center"
                  style="border-right: 1px solid grey;"
                  >
                  {{ activity.quantity }}
                </v-col>

                <v-col
                  cols="1"
                  class="d-flex align-center justify-center"
                  style="border-right: 1px solid grey;"
                  >
                  <template
                    >
                    {{ parseFloat(activity.price).toLocaleString('es-CL') }} CLP
                  </template>
                </v-col>

                <v-col
                  cols="1"
                  class="d-flex align-center justify-center"
                  style="border-right: 1px solid grey;"
                  >
                  <template
                    >
                    {{ (activity.price * (activity.quantity || 1)).toLocaleString('es-CL') }} CLP
                  </template>
                </v-col>

                <v-col
                  cols="4"
                  class="d-flex align-center justify-center text-center"
                  >
                  <v-text-field
                    v-model="activity.obs"
                    outlined
                    flat
                    solo
                    label="Observaciones"
                    hide-details
                    dense
                    @blur="editActivity(activity.id, activity.obs)"
                    ></v-text-field>
                </v-col>
              </v-row>
            </div>
          </div>
        </template>
      </div>
    </v-expand-transition>

    <div
      style="gap: 12px; border-bottom: 1px solid grey; border-left: 1px solid grey; border-right: 1px solid grey; cursor: pointer"
      class="text-center pa-2 font-weight-medium primary lighten-3 white--text"
      @click="showAdditionals = !showAdditionals"
      >
      Adicionales
    </div>

    <v-expand-transition>
      <div
        :key="showAdditionals"
        v-if="showAdditionals"
        >
        <template
          v-for="additional, i in rollout.additionals"
          >
          <div
            :style="additionals[i] ? 'background-color: #e4e4e4' : ''"
            style="gap: 12px; border-bottom: 1px solid grey; border-left: 1px solid grey; border-right: 1px solid grey"
            class="d-flex align-center px-2"
            >
            <div
              class="shrink"
              >
              <v-checkbox 
                class="mt-n1"
                hide-details
                v-model="additionals[i]"
                :value="additional.id"
                />
            </div>
            <div
              class="grow py-3"
              >
              <v-row
                class="align-stretch"
                >
                <v-col
                  cols="1"
                  class="d-flex align-center justify-center"
                  style="border-right: 1px solid grey;"
                  >
                  Adicional:
                </v-col>

                <v-col
                  :cols="project.projectType == 'nokia' ? 3 : 4"
                  style="border-right: 1px solid grey; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                  class="d-flex align-center"
                  >
                  <v-tooltip 
                    color="primary"
                    bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                        >
                        {{ !!additionalsPricing[additional.additional] ? '' : 'FS - ' }}{{ additional.additional }}
                      </span>
                    </template>
                    <span>
                      {{ additional.additional }}
                    </span>
                  </v-tooltip>
                </v-col>

                <v-col
                  cols="1"
                  class="d-flex align-center justify-center"
                  style="border-right: 1px solid grey;"
                  >
                  {{ additional.quantity }}
                </v-col>

                <v-col
                  cols="1"
                  class="d-flex align-center justify-center"
                  style="border-right: 1px solid grey;"
                  v-if="project.projectType == 'nokia'"
                  >
                </v-col>

                <v-col
                  cols="1"
                  class="d-flex align-center justify-center"
                  style="border-right: 1px solid grey;"
                  >
                  {{ (additional.amount || 0).toLocaleString('es-CL') }}
                </v-col>

                <v-col
                  cols="1"
                  class="d-flex align-center justify-center"
                  style="border-right: 1px solid grey;"
                  >
                  <template>
                    {{ (additional.totalAmount || 0).toLocaleString('es-CL') }}
                  </template>
                </v-col>

                <v-col
                  cols="4"
                  class="d-flex align-center justify-center text-center"
                  >
                  <v-text-field
                    v-model="additional.obs"
                    outlined
                    flat
                    solo
                    label="Observaciones"
                    hide-details
                    dense
                    @blur="editAdditional(additional.id, additional.obs)"
                    ></v-text-field>
                </v-col>
              </v-row>
            </div>
          </div>
        </template>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import ActivityHeader from './ActivityHeader'
import { Activities, Additionals } from '@/utils/prices'
import { EditRollout } from '@/graphql/mutations/rollouts'

import { mapGetters } from 'vuex'

export default {
  data: () => ({
    success: false,
    showAdditionals: false,
    showActivities: true,
    additionals: [],
    activities: [],
    activitiesPricing: Activities,
    additionalsPricing: Additionals
  }),

  props: {
    rollout: {
      required: true,
      type: Object
    }
  },

  computed: {
    ...mapGetters(['project'])
  },

  created () {
    if (this.project.projectType == 'nokia') {
      this.rollout.nokiaActivities.forEach ( (activity, idx) => {
        if (activity.checked) {
          this.activities[idx] = activity.id
        }
      })
    } else {
      this.rollout.huaweiActivities.forEach ( (activity, idx) => {
        if (activity.checked) {
          this.activities[idx] = activity.id
        }
      })
    }

    this.rollout.additionals.forEach ( (additional, idx) => {
      if (additional.checked) {
        this.additionals[idx] = additional.id
      }
    })
  },

  methods: {
    selectAll () {
      if (this.rollout.nokiaActivities.length == this.activities.length && this.rollout.additionals.length == this.additionals.length) {
        this.activities = []
        this.additionals = []
      } else {
        this.activities = this.rollout.nokiaActivities.map ( activity => activity.id )
        this.additionals = this.rollout.additionals.map ( additional => additional.id )
      }
    },

    editAdditional (id, obs) {
      this.$apollo.mutate({
        mutation: EditRollout,
        variables: {
          input: {
            id: this.rollout.id,
            attributes: {
              additionalsAttributes: [
                {
                  id: id,
                  obs: obs
                }
              ]
            }
          }
        }
      }).then ( res => {
        this.success = true
        this.$emit('reload')
      })
    },

    editActivity (id, obs) {
      this.$apollo.mutate({
        mutation: EditRollout,
        variables: {
          input: {
            id: this.rollout.id,
            attributes: {
              nokiaActivitiesAttributes: [
                {
                  id: id,
                  obs: obs
                }
              ]
            }
          }
        }
      }).then ( res => {
        this.success = true
        this.$emit('reload')
      })
    },
  },

  components: {
    ActivityHeader
  }
}
</script>
