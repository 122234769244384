<template>
  <v-card
    v-if="currentUser && rollout"
    :disabled="!(['shibui', 'finance', 'management'].includes(currentUser.currentRoleName))"
    rounded="lg"
    height="100%"
    class="d-flex flex-column"
    color="transparent"
    >
    <v-card-title
      class="py-1 primary white--text lower-opacity"
      >
      LPU / PO
    </v-card-title>

    <v-card-text
      class="lower-background-opacity grow"
      >
      <template
        v-if="(['shibui', 'finance', 'management'].includes(currentUser.currentRoleName))"
        >
        <list
          :pos="pos"
          ></list>

        <div
          class="text-right"
          v-if="pos.length"
          >
        </div>
      </template>
    </v-card-text>

    <v-card-actions
      class="lower-background-opacity"
      >
      <div
        class="grow d-flex justify-end"
        style="gap: 12px"
        >
        <go-to-bill
          :key="rollout.id"
          :pos="pos"
          :rollout="rollout"
          :site="site"
          @reload="reload"
          ></go-to-bill>

        <create
          :rollout="rollout"
          :pos="pos"
          @reload="reload"
          ></create>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

import { Pos } from '@/graphql/queries/pos'
import Create from './po/Create'
import List from './po/List'
import GoToBill from './po/Bill'

export default {
  data: () => ({
    pos: []
  }),

  props: {
    rollout: {
      required: false,
      type: Object
    },
    site: {
      required: false,
      type: Object
    }
  },

  watch: {
    rollout (val) {
      if (val && val.id) {
        this.fetchPos ()
      }
    }
  },

  computed: {
    ...mapGetters(['currentUser'])
  },

  created () {
    if (this.rollout && this.rollout.id) {
      this.fetchPos ()
    }
  },

  methods: {
    fetchPos () {
      this.$apollo.query({
        query: Pos,
        variables: {
          rolloutId: this.rollout.id
        },
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.pos = res.data.pos
      })
    },

    reload (id) {
      this.$emit('reload', this.rollout.id)
      this.fetchPos()
    }
  },

  components: { Create, List, GoToBill }
}
</script>
