<template>
  <v-form
    v-model="valid"
    ref="form"
    lazy-validation
    >
    <v-autocomplete
      outlined
      :items="assignees"
      label="Responsables"
      v-model="rollout.assignees"
      chips
      small-chips
      hide-details
      dense
      multiple
      style="background-color: white"
      :disabled="!['shibui', 'rollout', 'management'].includes(currentUser.currentRoleName) || ['total', 'closed', 'cancelled'].includes(rollout.status)"
      class="mb-3"
      >
      <template v-slot:selection="data">
        <v-chip
          v-bind="data.attrs"
          close
          small
          close-icon="mdi-check-circle"
          @click:close="updateSupervisor(data.item)"
          :color="rollout.supervisor == data.item ? 'success' : ''"
          >
          {{ data.item }}
        </v-chip>
      </template>
    </v-autocomplete>

    <v-textarea
      outlined
      label="Observaciones"
      v-model="rollout.obs"
      hide-details
      dense
      style="background-color: white"
      class="mb-3"
      ></v-textarea>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex'
import { CreateRollout } from '@/graphql/mutations/rollouts'

export default {
  data: () => ({
    assignees: [
      "CRISTOHER RUZ COTAL",
      "DAVID LEIVA CAYULEO",
      "IGNACIO MENESES GUERRA",
      "JONATHAN ANTONIO QUIÑONES GUZMAN",
      "MARCELO GARRIDO NUÑEZ",
      "RODRIGO POZO TRONCOZO",
      "VICTOR MANUEL ORMAZABAL ARAVENA",
      "WILLIAMS ZUÑIGA GODOY",
      "ANDRES ANTONIO GOMEZ LOVERA",
      "KLIBER GERARDO DIAZ GARABAN",
      "ARTURO QUILLA VALENCIA",
      "JONATHAN CASTRO GARRIDO",
      "AGUSTIN APABLAZA ZUÑIGA",
      "FRED NELSON CID ZUÑIGA",
      "ENZO RODRIGO RODRIGUEZ TORO",
      "FRANCISCO ASTORGA CACERES",
      "ARDANY TIMOTE PRADO",
      "CARLOS ORDOÑEZ ABELLO",
      "EDUARDO HERNANDEZ MARTINEZ",
      "EDUARDO VIDAL OLAVE",
      "ELIAS ALBERTO GALDAMEZ NAVARRETE",
      "JONATHAN PAREDES SILVA",
      "LUIS ALEJANDRO DELGADO GUZMAN",
      "LUIS CARLOS MUJICA LOVERA",
      "PATRICIO LOPEZ REYES",
      "SEBASTIAN CARRASCO CID",
      "ISAIAS GONZALEZ RUDIO",
      "GERARDO CONCHA",
      "PEDRO PEÑA",
    ],
    rollout: {
      obs: null,
      assignees: null,
      supervisor: null,
    },
    valid: true
  }),

  computed: {
    ...mapGetters(['currentUser'])
  },

  props: {
    site: {
      type: Object,
      required: true
    }
  },

  methods: {
    createRollout () {
      this.$apollo.mutate({
        mutation: CreateRollout,
        variables: {
          input: {
            projectId: this.$route.params.id,
            siteId: this.site.id,
            attributes: this.rollout
          }
        }
      }).then ( res => {
        this.$emit('search', res.data.createRollout.rollout.id)
      })
    },

    updateSupervisor (item) {
      this.rollout.supervisor = item
    }
  },
}
</script>
