<template>
  <v-dialog
    v-model="dialog"
    width="1000"
    >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        depressed
        :disabled="!rollout.id"
        color="primary"
        class="rounded-lg py-5"
        v-bind="attrs"
        v-on="on"
        >
        <img
          src="@/assets/icons/tiantar-form.svg"
          height="36px"
          width="36px"
          ></img>
      </v-btn>
    </template>

    <v-card>
      <v-card-title
        class="py-2 primary white--text lower-opacity"
        >
        LPU / PO
      </v-card-title>

      <v-card-text
        class="pt-3 mt-3 lower-background-opacity"
        >
        <v-row
          >
          <v-col
            class="pb-0"
            cols="7"
            >
            <v-row
              class="fill-height"
              style="align-content: space-between"
              >
              <v-col
                cols="12"
                style="height: 375px; overflow-y: auto"
                >
                <v-row
                  :key="pos.length"
                  >
                  <template
                    v-for="po in pos"
                    >
                    <v-col
                      cols="12"
                      class="pt-0"
                      >
                      <po-card
                        :po="po"
                        :deleting="!(rollout.status == 'closed' || po.checked)"
                        :rollout="rollout"
                        @deleting="pushToDelete"
                        @reload="$emit('reload')"
                        ></po-card>
                    </v-col>
                  </template>
                </v-row>
              </v-col>

              <v-col
                cols="12"
                class="text-right py-0 mt-auto"
                >
                <delete-po
                  v-if="deleting.length"
                  :ids="deleting"
                  @reload="reload"
                  ></delete-po>

                <v-btn
                  depressed
                  color="primary"
                  class="py-5 ml-2 rounded-lg"
                  @click="dialog = false"
                  >
                  Cerrar
                </v-btn>
              </v-col>
            </v-row>
          </v-col>

          <v-spacer
            class="text-center"
            >
            <v-divider
              vertical
              ></v-divider>
          </v-spacer>

          <v-col
            cols="4"
            class="pb-0"
            >
            <v-card
              :disabled="!['shibui', 'management', 'finance'].includes(currentUser.currentRoleName) || (rollout && ['closed', 'cancelled'].includes(rollout.status))"
              flat
              color="transparent"
              >
              <v-file-input
                ref="file"
                class="mb-3"
                style="background-color: white"
                outlined
                label="Examinar"
                hide-details
                dense
                multiple
                accept=".csv"
                @change="onChange"
                ></v-file-input>

              <div
                >
                <v-card
                  @dragover="dragover" 
                  @dragleave="dragleave" 
                  @drop="drop"
                  outlined
                  height="300"
                  @click="clickInput"
                  >
                  <v-card-text
                    class="fill-height"
                    style="overflow: auto"
                    >
                    <div
                      v-if="!files.length"
                      class="d-flex justify-center fill-height"
                      >
                      <div
                        class="fill-height d-flex justify-center align-center primary--text text-center"
                        >
                        Arrastre sus archivos PDF aquí o haga click para examinar.
                      </div>
                    </div>

                    <div
                      v-else
                      :key="files.length"
                      >
                      <template
                        v-for="(file, i) in files"
                        >
                        <v-card
                          class="fill-width mb-2"
                          outlined
                          @click.stop="expand(file.name)"
                          :key="file.name"
                          :color="(error[file.name] || {}).active ? 'error lighten-2' : (successful[file.name] ? 'success lighten-2' : '')"
                          >
                          <v-card-text
                            :key="expanding.includes(file.name)"
                            class="py-2 d-flex justify-space-between align-center"
                            >
                            <div
                              style="max-width: 220px !important"
                              class="grow"
                              >
                              {{ file.name }}
                            </div>

                            <div
                              class="mr-n1 shrink"
                              >
                              <v-btn
                                icon
                                small
                                color="red"
                                @click.stop="remove(i)"
                                >
                                <v-icon small>mdi-close</v-icon>
                              </v-btn>
                            </div>
                          </v-card-text>

                          <template
                            v-if="(error[file.name] || {}).active && expanding.includes(file.name)"
                            >
                            <v-divider></v-divider>

                            <div
                              >
                              <div
                                class="pa-2"
                                >
                                {{ $t('errors.' + error[file.name].message) }}
                              </div>
                            </div>
                          </template>
                        </v-card>
                      </template>
                    </div>
                  </v-card-text>
                </v-card>
              </div>

              <div
                class="text-right py-3"
                >
                <v-btn
                  class="mt-3 rounded-lg"
                  depressed
                  color="primary"
                  :disabled="!files.length || loading"
                  :loading="loading"
                  @click="upload"
                  >
                  Subir
                </v-btn>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

import { CreatePo } from '@/graphql/mutations/pos'
import PoCard from '@/components/sites/main/po/Card'
import DeletePo from '@/components/sites/main/po/Delete'

export default {
  data: () => ({
    dialog: false,
    loading: false,
    deleting: [],
    expanding: [],
    files: [],
    error: {},
    successful: {}
  }),

  props: {
    rollout: {
      required: true,
      type: Object
    },

    pos: {
      required: false,
      type: Array,
      default: () => {
        return []
      }
    },
  },

  watch: {
    dialog (val) {
      if (!val) {
        this.loading = false
        this.deleting = []
        this.expanding = []
        this.files = []
        this.error = {}
        this.successful = {}
        this.$forceUpdate ()
      }
    }
  },

  computed: {
    ...mapGetters(['currentUser'])
  },

  methods: {
    onChange() {
      this.files = [
        ...this.files,
        ...this.$refs.file.$el.getElementsByTagName('input')[0].files
      ];

      this.$nextTick( () => {
        this.$refs.file.$el.getElementsByTagName('input')[0].value = null
        this.$forceUpdate()
      })
    },

    remove(i) {
      this.files.splice(i, 1);
    },

    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('success')) {
        event.currentTarget.classList.add('success');
      }
    },

    dragleave(event) {
      // Clean up
      event.currentTarget.classList.remove('success');
    },

    drop(event) {
      event.preventDefault();

      this.files = [
        ...this.files,
        ...event.dataTransfer.files
      ]

      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.remove('success');
    },

    clickInput () {
      this.$refs.file.$el.getElementsByTagName('input')[0].click()
    },

    upload () {
      this.loading = true

      let filesLength = this.files.length

      this.files.forEach ( (file, i) => {
        this.$apollo.mutate({
          mutation: CreatePo,
          variables: {
            input: {
              attributes: {
                file: file,
                rolloutId: this.rollout.id
              },
            }
          }
        }).then ( res => {
          this.$emit('reload')
          this.successful[file.name] = true

          if (i == (filesLength - 1)) {
            this.loading = false
          }
        }).catch ( err => {
          this.error[file.name] = {
            active: true,
            message: `${err.graphQLErrors[0].extensions.field}_${err.graphQLErrors[0].extensions.message}`
          }

          if (i == (filesLength - 1)) {
            this.loading = false
          }
        })
      })
    },

    pushToDelete (val) {
      if (this.deleting.includes(val)) {
        let idx = this.deleting.indexOf(val)
        this.deleting.splice(idx, 1)
      } else {
        this.deleting.push(val)
      }
    },

    expand (val) {
      if (this.expanding.includes(val)) {
        let idx = this.expanding.indexOf(val)
        this.expanding.splice(idx, 1)
      } else {
        this.expanding.push(val)
      }
    },

    reload () {
      this.$emit('reload')
      this.deleting = []
    }
  },

  components: { PoCard, DeletePo }
}
</script>
