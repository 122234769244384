import gql from 'graphql-tag'

export const Stocks = gql`
  query stocks($rolloutId: ID!, $stockLocationId: ID) {
    stocks(rolloutId: $rolloutId, stockLocationId: $stockLocationId) {
      id
      stock
      serialNumber
      stockLocationId
      installedQuantity
      rolloutId
      stockLocation {
        id
        name
      }
      lastMovement {
        id
        movementType
        approved
      }
      item {
        id
        sku
        description
        condition
      }
    }
  }
`
