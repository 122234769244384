var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',{staticClass:"pt-3",attrs:{"flat":"","loading":_vm.loading,"disabled":_vm.loading}},[_vm._l((_vm.attributes.additionalsAttributes),function(activity,idx){return [(!activity._destroy)?_c('div',[_c('v-row',[_c('v-col',{staticClass:"pr-0 pb-0 grow",attrs:{"cols":"auto"}},[_c('v-combobox',{staticClass:"mb-3",staticStyle:{"background-color":"white"},attrs:{"outlined":"","items":_vm.additionals[_vm.project.projectType],"label":"Adicional","prefix":!_vm.additionals[_vm.project.projectType].includes(activity.additional) ? 'FS -' : '',"dense":"","mandatory":"","rules":[
                function (v) { return !!v || 'Campo obligatorio'; } ],"search-input":_vm.search[idx],"hide-no-data":!_vm.search[idx]},on:{"update:searchInput":function($event){return _vm.$set(_vm.search, idx, $event)},"update:search-input":function($event){return _vm.$set(_vm.search, idx, $event)},"blur":function($event){return _vm.setPrice(activity)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('span',{staticClass:"subheading mr-1"},[_vm._v("Presion enter para crear")]),_c('v-chip',{attrs:{"color":"primary","label":"","small":""}},[_vm._v(" "+_vm._s(_vm.search[idx])+" ")])],1)]},proxy:true}],null,true),model:{value:(activity.additional),callback:function ($$v) {_vm.$set(activity, "additional", $$v)},expression:"activity.additional"}})],1),(['OVP', 'Desinstalación de Feeders'].includes(activity.additional))?_c('v-col',{staticClass:"pr-0 pb-0",attrs:{"cols":"2"}},[_c('v-text-field',{staticClass:"mb-3",staticStyle:{"background-color":"white"},attrs:{"outlined":"","label":"Metros","type":"number","dense":"","rules":[
                function (v) { return !!v || 'Campo obligatorio'; },
                function (v) { return (v || 1) > 0 || 'Debe ser mayor a 0'; }
              ]},on:{"blur":function($event){return _vm.setPrice(activity)}},model:{value:(activity.meters),callback:function ($$v) {_vm.$set(activity, "meters", _vm._n($$v))},expression:"activity.meters"}})],1):_vm._e(),_c('v-col',{staticClass:"pr-0 pb-0",attrs:{"cols":"2"}},[_c('v-text-field',{staticClass:"mb-3",staticStyle:{"background-color":"white"},attrs:{"outlined":"","label":"Cantidad","type":"number","dense":"","rules":[
                function (v) { return !!v || 'Campo obligatorio'; },
                function (v) { return (v || 1) > 0 || 'Debe ser mayor a 0'; }
              ]},on:{"blur":function($event){return _vm.setPrice(activity)}},model:{value:(activity.quantity),callback:function ($$v) {_vm.$set(activity, "quantity", _vm._n($$v))},expression:"activity.quantity"}})],1),_c('v-col',{staticClass:"pr-0 pb-0 text-right",attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.destroyActivity(activity)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)],1)],1)],1):_vm._e()]}),_c('div',{staticClass:"text-right"},[_c('v-btn',{attrs:{"outlined":"","color":"success"},on:{"click":_vm.pushActivity}},[_vm._v(" Añadir adicional ")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }