<template>
  <v-form
    v-model="valid"
    ref="form"
    lazy-validation
    >
    <v-row>
      <v-col
        cols="6"
        >
        <v-autocomplete
          outlined
          :items="workTypes"
          label="Tipo de trabajo"
          v-model="attributes.workType"
          hide-details
          dense
          style="background-color: white"
          class="mb-3"
          ></v-autocomplete>
      </v-col>

      <v-col
        cols="6"
        class="pb-0"
        >
        <v-autocomplete
          v-if="attributes.workType == 'TSS'"
          outlined
          chips
          small-chips
          :items="['5G', '4G']"
          label="Tecnología"
          v-model="attributes.technology"
          dense
          multiple
          mandatory
          style="background-color: white"
          class="mb-3"
          ></v-autocomplete>
      </v-col>
    </v-row>

    <div
      v-if="!(attributes.workType == 'TSS')"
      class="pt-3"
      >
      <template
        v-for="activity in attributes.nokiaActivitiesAttributes"
        >
        <div
          v-if="!activity._destroy"
          >
          <v-row>
            <v-col
              cols="2"
              class="pr-0 pb-0"
              >
              <v-autocomplete
                outlined
                :items="['5G', '4G']"
                label="Tecnología"
                v-model="activity.technology"
                dense
                style="background-color: white"
                class="mb-3"
                ></v-autocomplete>
            </v-col>

            <v-col
              cols="5"
              class="pr-0 pb-0"
              >
              <v-autocomplete
                outlined
                :items="scenes[activity.technology]"
                item-text="scene"
                item-value="scene"
                label="Escena"
                v-model="activity.scene"
                dense
                mandatory
                style="background-color: white"
                class="mb-3"
                :rules="[
                  v => !!v || 'Campo obligatorio',
                ]"
                ></v-autocomplete>
            </v-col>

            <v-col
              cols="2"
              class="pr-0 pb-0"
              >
              <v-text-field
                outlined
                label="SMP"
                v-model="activity.smp"
                dense
                style="background-color: white"
                class="mb-3"
                ></v-text-field>
            </v-col>

            <v-col
              cols="2"
              class="pb-0 pr-0"
              >
              <v-autocomplete
                outlined
                label="TSS"
                :items="[{ text: 'Sí', value: true }, { text: 'No', value: false }]"
                v-model="activity.tss"
                dense
                style="background-color: white"
                class="mb-3"
                ></v-autocomplete>
            </v-col>

            <v-col
              cols="1"
              class="pb-0 pl-0 text-right"
              >
              <v-btn
                icon
                color="error"
                @click="destroyActivity(activity)"
                >
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </template>

      <div
        class="text-right"
        >
        <v-btn
          outlined
          color="success"
          @click="pushActivity"
          >
          Añadir actividad
        </v-btn>
      </div>
    </div>
  </v-form>
</template>

<script>
import { EditRollout } from '@/graphql/mutations/rollouts'
import { NokiaPricing } from '@/graphql/queries/activities'
import { Activities } from '@/utils/prices'

export default {
  data: () => ({
    attributes: {
      technology: ['4G'],
      workType: 'TSS',
      nokiaActivitiesAttributes: [],
      additionalsAttributes: []
    },
    prices: Activities,
    scenes: {
      '4G': [
      ],
      '5G': [
      ]
    },
    workTypes: ['TSS', 'Implementación'],
    valid: true
  }),

  props: {
    rollout: {
      type: Object,
      required: true
    }
  },

  watch: {
    'attributes.workType': {
      handler (val) {
        let activities = this.attributes.nokiaActivitiesAttributes
        let additionals = this.attributes.additionalsAttributes

        if (val == 'TSS') {
          activities.forEach( (activity) => {
            activity._destroy = 1
          })

          additionals.forEach( (additional) => {
            additional._destroy = 1
          })
        } else {
          if (activities.length) {
            activities.forEach( (activity) => {
              activity._destroy = 0
            })
          } else {
            this.pushActivity ()
          }

          additionals.forEach( (additional) => {
            additional._destroy = 0
          })
        }
      }
    }
  },

  created () {
    this.fetchPricing ()

    if (this.rollout.workType) this.attributes.workType = this.rollout.workType
    if (this.rollout.technology) this.attributes.technology = this.rollout.technology

    delete this.rollout.huaweiActivities

    if (this.rollout.nokiaActivities.length) {
      this.rollout.nokiaActivities.forEach( (activity) => {
        delete activity.__typename
        delete activity.closed
        delete activity.linkId

        this.attributes.nokiaActivitiesAttributes.push(activity)
      })
    }

    if (this.rollout.additionals.length) {
      this.rollout.additionals.forEach( (additional) => {
        delete additional.__typename
        delete additional.closed
        delete additional.totalAmount

        this.attributes.additionalsAttributes.push(additional)
      })
    }
  },

  methods: {
    editRollout () {
      if (this.$refs.form.validate()) {
        this.$apollo.mutate({
          mutation: EditRollout,
          variables: {
            input: {
              id: this.rollout.id,
              attributes: this.attributes
            }
          }
        }).then ( res => {
          this.$emit('reload', res.data.editRollout.rollout.id)
        })
      }
    },

    fetchPricing () {
      this.$apollo.query({
        query: NokiaPricing,
        variables: {
          id: this.$route.params.id
        }
      }).then ( res => {
        this.scenes = res.data.nokiaPricing.reduce ( (r, i) => {
          r[i.technology] = r[i.technology] || []
          r[i.technology].push(i)
          return r
        }, Object.create(null)) 
      })
    },

    pushActivity () {
      this.attributes.nokiaActivitiesAttributes.push({
        id: null,
        quantity: 1,
        amount: 0,
        smp: null,
        technology: '5G',
        scene: null,
        tss: false,
      })
    },

    destroyActivity (activity) {
      activity._destroy = 1
      this.$forceUpdate()
    },
  },
}
</script>
