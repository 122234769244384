<template>
  <v-form
    v-model="valid"
    ref="form"
    lazy-validation
    >
    <v-card
      flat
      :loading="loading"
      :disabled="loading"
      class="pt-3"
      >
      <template
        v-for="(activity, idx) in attributes.additionalsAttributes"
        >
        <div
          v-if="!activity._destroy"
          >
          <v-row>
            <v-col
              cols="auto"
              class="pr-0 pb-0 grow"
              >
              <v-combobox
                outlined
                :items="additionals[project.projectType]"
                label="Adicional"
                v-model="activity.additional"
                :prefix="!additionals[project.projectType].includes(activity.additional) ? 'FS -' : ''"
                dense
                mandatory
                style="background-color: white"
                class="mb-3"
                :rules="[
                  v => !!v || 'Campo obligatorio',
                ]"
                :search-input.sync="search[idx]"
                :hide-no-data="!search[idx]"
                @blur="setPrice(activity)"
                >
                <template v-slot:no-data>
                  <v-list-item>
                    <span class="subheading mr-1">Presion enter para crear</span>
                    <v-chip
                      color="primary"
                      label
                      small
                      >
                      {{ search[idx] }}
                    </v-chip>
                  </v-list-item>
                </template>
              </v-combobox>
            </v-col>

            <v-col
              v-if="['OVP', 'Desinstalación de Feeders'].includes(activity.additional)"
              cols="2"
              class="pr-0 pb-0"
              >
              <v-text-field
                outlined
                label="Metros"
                v-model.number="activity.meters"
                type="number"
                dense
                style="background-color: white"
                :rules="[
                  v => !!v || 'Campo obligatorio',
                  v => (v || 1) > 0 || 'Debe ser mayor a 0'
                ]"
                class="mb-3"
                @blur="setPrice(activity)"
                ></v-text-field>
            </v-col>

            <v-col
              cols="2"
              class="pr-0 pb-0"
              >
              <v-text-field
                outlined
                label="Cantidad"
                v-model.number="activity.quantity"
                type="number"
                dense
                style="background-color: white"
                :rules="[
                  v => !!v || 'Campo obligatorio',
                  v => (v || 1) > 0 || 'Debe ser mayor a 0'
                ]"
                class="mb-3"
                @blur="setPrice(activity)"
                ></v-text-field>
            </v-col>

            <v-col
              cols="1"
              class="pr-0 pb-0 text-right"
              >
              <v-btn
                icon
                color="error"
                @click="destroyActivity(activity)"
                >
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </template>

      <div
        class="text-right"
        >
        <v-btn
          outlined
          color="success"
          @click="pushActivity"
          >
          Añadir adicional
        </v-btn>
      </div>
    </v-card>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex'
import { EditRollout } from '@/graphql/mutations/rollouts'
import { Rollout as RolloutQuery } from '@/graphql/queries/rollouts'

import { NokiaPricing } from '@/graphql/queries/activities'

import { Additionals } from '@/utils/prices'

export default {
  data: () => ({
    loading: false,
    attributes: {
      additionalsAttributes: [] 
    },
    additionals: {
      'nokia': [
      ],
      'huawei': [
      ]
    },
    prices: Additionals,
    valid: true,
    search: []
  }),

  computed: {
    ...mapGetters(['project'])
  },

  props: {
    rollout: {
      type: Object,
      required: true
    }
  },

  created () {
    this.fetchRollout ()
    this.fetchPricing ()
  },

  methods: {
    editRollout () {
      if (this.$refs.form.validate()) {
        this.$apollo.mutate({
          mutation: EditRollout,
          variables: {
            input: {
              id: this.rollout.id,
              attributes: this.attributes
            }
          }
        }).then ( res => {
          this.$emit('reload', res.data.editRollout.rollout.id)
        })
      }
    },

    pushActivity () {
      if (this.project.projectType == 'nokia') {
        var amount = this.prices["Revisita"]

        this.attributes.additionalsAttributes.push({
          id: null,
          quantity: 1,
          amount: amount,
          additional: "Revisita",
        })
      } else {
        this.attributes.additionalsAttributes.push({
          id: null,
          quantity: 1,
          amount: null,
          additional: null,
        })
      }
    },

    destroyActivity (activity) {
      activity._destroy = 1
      this.$forceUpdate()
    },

    fetchRollout () {
      this.loading = true
      this.$apollo.query({
        query: RolloutQuery,
        variables: {
          id: this.rollout.id
        },
        fetchPolicy: 'no-cache'
      }).then ( res => {
        if (res.data.rollout.workType == 'TSS') {
          this.$emit('reload', this.rollout.id)
        } else {
          if (this.rollout.additionals.length) {
            this.rollout.additionals.forEach( (activity) => {
              delete activity.__typename
              delete activity.closed
              delete activity.totalAmount

              this.attributes.additionalsAttributes.push(activity)
            })
          } else {
            this.pushActivity ()
          }
        }
        this.loading = false
      })
    },

    fetchPricing () {
      this.$apollo.query({
        query: NokiaPricing,
        variables: {
          id: this.$route.params.id
        }
      }).then ( res => {
        this.additionals['nokia'] = res.data.nokiaPricing.map( (item) => {
          if (item.technology == '4G,5G') {
            return item.scene
          }
        })

        res.data.nokiaPricing.forEach ( (item) => {
          if (this.prices[item.scene]) {
            if (this.prices[item.scene].multiplier) {
              this.prices[item.scene].multiplier = item.price
            } else {
              this.prices[item.scene] = item.price
            }
          } else {
            this.prices[item.scene] = item.price
          }
        })
      })
    },

    setPrice (additional) {
      if (additional.additional == "Desinstalación de Feeders") {
        var amount = (this.prices[additional.additional].multiplier * (Math.ceil((additional.meters || 0) / 10)))
      } else {
        var amount = this.prices[additional.additional] || 0
      }

      additional.amount = amount
    }
  },
}
</script>
