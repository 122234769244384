<template>
  <v-card
    v-if="currentUser"
    rounded="lg"
    class="shrink"
    color="transparent"
    >
    <v-card-title
      class="py-1 primary white--text lower-opacity"
      >
      Adicionales
    </v-card-title>

    <v-card-text
      style="height: 190px; overflow-y: auto"
      class="pt-6 lower-background-opacity"
      >
      <template
        v-for="activity in rollout.additionals"
        >
        <div
          v-if="!activity._destroy"
          >
          <v-row>
            <v-col
              cols="activity.additional == 'OVP' ? 8 : 10"
              class="pr-0 pb-0"
              >
              <v-text-field
                outlined
                label="Escena"
                readonly
                :value="activity.additional"
                :prefix="!additionals[project.projectType] || !additionals[project.projectType].includes(activity.additional) ? 'FS - ' : ''"
                dense
                hide-details
                style="background-color: white"
                class="mb-3"
                ></v-text-field>
            </v-col>

            <v-col
              v-if="activity.additional == 'OVP'"
              cols="2"
              class="pb-0 pr-0"
              >
              <v-text-field
                outlined
                label="Metros"
                readonly
                :value="activity.meters"
                dense
                hide-details
                style="background-color: white"
                class="mb-3"
                ></v-text-field>
            </v-col>

            <v-col
              cols="2"
              class="pb-0"
              >
              <v-text-field
                outlined
                label="Cantidad"
                readonly
                :value="activity.quantity"
                dense
                hide-details
                style="background-color: white"
                class="mb-3"
                ></v-text-field>
            </v-col>
          </v-row>
        </div>
      </template>
    </v-card-text>

    <v-card-actions
      class="lower-background-opacity"
      style="gap: 8px"
      >
      <v-spacer></v-spacer>
      <close
        v-if="(['shibui', 'rollout', 'management'].includes(currentUser.currentRoleName)) && rollout && rollout.additionals && rollout.additionals.length"
        :disabled="!!rollout.additionals[0].closed"
        activity="additional"
        :id="rollout.id"
        @reload="reload"
        ></close>

      <done
        :key="band"
        :rollout="rollout"
        @reload="reload"
        ></done>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { NokiaPricing } from '@/graphql/queries/activities'

import Done from './misc/Done'
import Close from './activities/Close'

export default {
  data: () => ({
    band: 0,
    additionals: {
      'nokia': [
      ],
      'nokia': [
      ]
    },
  }),

  props: {
    rollout: {
      required: true,
      type: Object
    }
  },

  created () {
    this.fetchPricing ()
  },

  computed: {
    ...mapGetters(['currentUser', 'project'])
  },

  methods: {
    reload () {
      this.$emit('reload', this.rollout.id)
      this.band += 1
    },

    fetchPricing () {
      this.$apollo.query({
        query: NokiaPricing,
        variables: {
          id: this.$route.params.id
        }
      }).then ( res => {
        this.additionals['nokia'] = res.data.nokiaPricing.map( (item) => {
          if (item.technology == '4G,5G') {
            return item.scene
          }
        })
      })
    },
  },

  components: { Done, Close }
}
</script>
