<template>
  <div
    class="pt-3"
    >
    <div
      class="d-flex justify-space-between align-center mb-3"
      >
      <div>
        Listado de POs
      </div>
      <div
        class="caption"
        >
        {{ pos.length }} PO(s) cargadas
      </div>
    </div>

    <div
      style="max-height: 530px; overflow-y: auto"
      >
      <template
        v-for="po in pos"
        >
        <po-card
          :po="po"
          class="mb-3"
          ></po-card>
      </template>
    </div>
  </div>
</template>

<script>
import PoCard from '@/components/sites/main/po/Card'

export default {
  props: {
    pos: {
      required: true,
      type: Array,
    }
  },

  components: { PoCard }
}
</script>
