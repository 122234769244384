<template>
  <v-dialog
    v-model="dialog"
    width="1000"
    v-if="currentUser"
    >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="!list"
        :disabled="!(['shibui', 'rollout', 'management'].includes(currentUser.currentRoleName)) || !rollout.id || ['closed', 'cancelled'].includes(rollout.status) || (rollout.huaweiActivities && !!rollout.huaweiActivities.length && !!rollout.huaweiActivities[0].closed)"
        depressed
        color="primary"
        class="rounded-lg py-5"
        v-bind="attrs"
        v-on="on"
        >
        <img
          src="@/assets/icons/tiantar-form.svg"
          height="36px"
          width="36px"
          ></img>
      </v-btn>
    </template>

    <v-card>
      <v-card-title
        class="py-2 primary white--text lower-opacity"
        >
        Actividades
      </v-card-title>

      <v-card-text
        class="pt-6 lower-background-opacity"
        >
        <activities-form
          :rollout="rollout"
          ref="activitiesForm"
          @reload="reload"
          ></activities-form>
      </v-card-text>

      <v-card-actions
        class="pa-2"
        >
        <div
          class="grow text-right"
          >
          <v-btn
            depressed
            color="primary"
            class="rounded-lg py-5"
            @click="confirm"
            >
            Guardar
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import ActivitiesForm from './Form.vue'

export default {
  data: () => ({
    dialog: false,
  }),

  props: {
    rollout: {
      type: Object,
      required: false
    },

    list: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    ...mapGetters(['currentUser'])
  },

  methods: {
    editActivities () {
      this.$refs.activitiesForm.editRollout()
    },

    confirm () {
      this.editActivities ()
    },

    reload () {
      this.$emit('reload')
      this.dialog = false
    },
  },

  components: { ActivitiesForm }
}
</script>
