<template>
  <v-row
    class="align-stretch fill-height"
    v-if="project"
    >
    <v-col
      cols="12"
      md="4"
      >
      <rollout
        :rollout="rollout"
        :rollouts="rollouts"
        :site="site"
        @search="fetchRollout"
        @updateState="updateState"
        @refresh="$emit('refresh')"
      ></rollout>
    </v-col>

    <v-col
      cols="12"
      md="4"
      class="d-flex flex-column"
      >
      <div
        class="grow"
        >
        <tasks
          v-if="rollout"
          :key="rollout.id"
          :rollout="rollout"
          @reload="fetchRollout"
        ></tasks>
      </div>
      <div
        class="mt-3"
        >
        <misc
          v-if="rollout"
          :key="rollout.id"
          :rollout="rollout"
          @reload="fetchRollout"
        ></misc>
      </div>
    </v-col>

    <v-col
      cols="12"
      md="4"
      v-if="currentUser"
      style="position: relative"
      >
      <v-switch
        v-model="showPo"
        v-if="['shibui', 'management'].includes(currentUser.currentRoleName)"
        hide-details
        class="mt-0"
        color="white"
        style="position: absolute; right: 16px; top: 18px; z-index: 8;"
        dense
        ></v-switch>

      <template
        v-if="showPo"
        >
        <po
          v-if="project.projectType != 'huawei'"
          :key="rollout.id"
          :rollout="rollout"
          :site="site"
          @reload="fetchRollout"
          ></po>

        <huawei-po
          v-else
          :key="rollout.id"
          :rollout="rollout"
          :site="site"
          @reload="fetchRollout"
          ></huawei-po>
      </template>

      <template
        v-else
        >
        <stocks
          :id="rollout.id"
          ></stocks>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import { Rollout as RolloutQuery } from '@/graphql/queries/rollouts'

import Rollout from '@/components/sites/main/Rollout'
import Tasks from '@/components/sites/main/Tasks'
import Misc from '@/components/sites/main/Misc'
import Po from '@/components/sites/main/Po'
import HuaweiPo from '@/components/sites/main/HuaweiPo'
import Stocks from '@/components/sites/main/Stocks'

export default {
  data: () => ({
    rollout: {},
    showPo: false
  }),

  components: { Rollout, Tasks, Misc, Po, HuaweiPo, Stocks },

  props: {
    rollouts: {
      type: Array,
      required: true
    },

    site: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters(['project', 'currentUser'])
  },

  watch: {
    currentUser () {
      if (this.currentUser && ['shibui', 'finance', 'management'].includes(this.currentUser.currentRoleName))
        this.showPo = true
    }
  },

  created () {
    if (this.currentUser && ['shibui', 'finance', 'management'].includes(this.currentUser.currentRoleName))
      this.showPo = true
  },

  methods: {
    fetchRollout (id) {
      this.$apollo.query({
        query: RolloutQuery,
        variables: {
          id: id
        },
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.rollout = res.data.rollout || {}
        this.$forceUpdate()
      })
    },

    updateState (state) {
      this.$emit('updateState', state)
    }
  }
}
</script>
