var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-snackbar',{attrs:{"color":"success"},model:{value:(_vm.success),callback:function ($$v) {_vm.success=$$v},expression:"success"}},[_c('div',{staticClass:"text-center font-weight-bold"},[_vm._v(" ¡Sitio creado con éxito! ")])]),_c('v-card',{attrs:{"rounded":"lg","color":"transparent"}},[_c('v-card-title',{staticClass:"py-1 primary white--text lower-opacity"},[_vm._v(" Datos del sitio "),_c('v-spacer'),_c('v-autocomplete',{staticClass:"rounded-lg primary ml-3",staticStyle:{"max-width":"300px"},attrs:{"dense":"","solo":"","flat":"","label":"Buscar sitio","prepend-inner-icon":"mdi-magnify","items":_vm.sites,"item-text":"siteId","item-value":"id","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-text',{staticClass:"pt-3 lower-background-opacity"},[(_vm.site)?_c('v-row',{key:_vm.site.id,attrs:{"align":"end"}},[_c('v-col',{staticClass:"pr-0",attrs:{"lg":"11","md":"10"}},[_c('v-row',[_vm._l((_vm.headers),function(header){return [_c('v-col',{staticClass:"pr-0",attrs:{"cols":['lng', 'lat', 'height', 'siteId'].includes(header) ? 
                  1 : 
                  (['structureType', 'structureCode', 'towerOwner', 'chief', 'region', 'neighbourhood', 'address', 'name'].includes(header) ?
                    2 :
                    (header == 'obs' ? 4 : 3))}},[(header == 'obs')?_c('v-dialog',{attrs:{"width":"1200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticStyle:{"background-color":"white"},attrs:{"outlined":"","label":_vm.$t('fields.site.' + header),"value":_vm.site[header],"readonly":"","hide-details":"","rows":"1","dense":""}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"mb-3"},[_vm._v(" Observaciones ")]),_c('v-card-text',[_c('v-textarea',{staticStyle:{"background-color":"white"},attrs:{"outlined":"","label":_vm.$t('fields.site.' + header),"value":_vm.site[header],"readonly":"","hide-details":"","rows":"3","auto-grow":"","dense":""}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cerrar ")])],1)],1)],1):_c('v-text-field',{staticStyle:{"background-color":"white"},attrs:{"outlined":"","label":_vm.$t('fields.site.' + header),"value":_vm.site[header],"readonly":"","hide-details":"","dense":""}})],1)]})],2)],1),_c('v-col',{staticClass:"d-flex justify-end",staticStyle:{"gap":"12px"},attrs:{"lg":"1","md":"2"}},[_c('edit',{attrs:{"site":_vm.site},on:{"reload":function($event){return _vm.$emit('search', _vm.search)}}}),_c('create',{on:{"reload":_vm.newSite}})],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }