import gql from 'graphql-tag'

export const Rollout = gql`
  query rollout($id: ID!) {
    rollout(id: $id) {
      id
      status
      createdAt
      assignationDate
      startAt
      endsAt
      documentationSentAt
      approvedAt
      workType
      obs
      technology
      assignees
      supervisor

      nokiaActivities {
        id
        quantity
        technology
        scene
        tss
        checked
        smp
        closed
        amount
        obs
      }

      huaweiActivities {
        id
        closed
        checked
        number
        category
        activityType
        item
        description
        uom
        price
        quantity
        linkId
      }

      additionals {
        id
        quantity
        meters
        additional
        checked
        closed
        amount
        totalAmount
        obs
      }

      huaweiPos {
        id
      }

      pos {
        id
      }
    }
  }
`

export const Rollouts = gql`
  query rollouts($siteId: ID, $projectId: ID!, $state: [String!]) {
    rollouts(siteId: $siteId, projectId: $projectId, state: $state) {
      id
    }
  }
`

export const RolloutsList = gql`
  query rolloutsList($projectId: ID!, $state: [String!], $page: ID!, $per: ID!, $siteId: ID, $filters: RolloutFilter) {
    rolloutsList(projectId: $projectId, state: $state, page: $page, per: $per, siteId: $siteId, filters: $filters) {
      pages
      rollouts {
        id
        status
        createdAt
        assignationDate
        startAt
        endsAt
        documentationSentAt
        approvedAt
        workType
        obs
        technology
        nokiaActivities {
          id
          quantity
          technology
          scene
          tss
          checked
          smp
          closed
          amount
          obs
        }
        huaweiActivities {
          id
          closed
          checked
          number
          category
          activityType
          item
          description
          uom
          price
          quantity
        }
        additionals {
          id
          quantity
          meters
          additional
          checked
          closed
          amount
          obs
        }
        site {
          id
          siteId
          name
          region
        }
      }
    }
  }
`

export const RolloutCounting = gql`
  query rolloutCounting($projectId: ID!, $siteId: ID!) {
    rolloutCounting(projectId: $projectId, siteId: $siteId) {
      pending
      closed
      cancelled
    }
  }
`
