<template>
  <div
    style="gap: 12px;"
    class="d-flex align-center primary lighten-2 pa-2 rounded-tr-lg"
    >
    <div
      class="shrink"
      >
      <v-btn 
        icon
        color="transparent"
        disabled
        small
        />
    </div>
    <div
      class="grow"
      >
      <v-row
        class="align-center"
        >
        <v-col
          cols="1"
          class="text-center py-1 white--text font-weight-medium"
          >
          Tarea
        </v-col>

        <v-col
          :cols="project.projectType == 'nokia' ? 3 : 4"
          class="text-center py-1 white--text font-weight-medium"
          >
          Descripción
        </v-col>

        <v-col
          cols="1"
          class="text-center py-1 white--text font-weight-medium"
          >
          Cantidad
        </v-col>

        <v-col
          v-if="project.projectType == 'nokia'"
          cols="1"
          class="text-center py-1 white--text font-weight-medium"
          >
          Tecnología
        </v-col>

        <v-col
          cols="1"
          class="text-center py-1 white--text font-weight-medium"
          >
          Precio unitario
        </v-col>

        <v-col
          cols="1"
          class="text-center py-1 white--text font-weight-medium"
          >
          Monto
        </v-col>

        <v-col
          cols="4"
          class="text-center py-1 white--text font-weight-medium"
          >
          Comentarios
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['project'])
  }
}
</script>
