<template>
  <v-dialog
    v-if="currentUser"
    v-model="dialog"
    width="1000"
    >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="!list"
        :disabled="!(['rollout', 'management', 'shibui', 'documentation'].includes(currentUser.currentRoleName)) || !rollout.id"
        depressed
        color="primary"
        class="rounded-lg py-5"
        v-bind="attrs"
        v-on="on"
        >
        <img
          src="@/assets/icons/tiantar-form.svg"
          height="36px"
          width="36px"
          ></img>
      </v-btn>
    </template>

    <v-card>
      <v-card-title
        class="py-2 primary white--text lower-opacity"
        >
        Rollout
      </v-card-title>

      <v-card-text
        class="pt-3 lower-background-opacity"
        >
        <v-row>
          <template
            v-for="field, i in fields"
            >
            <v-col
              cols="12"
              md="4"
              >
              <v-dialog
                ref="dialog"
                v-model="modal[i]"
                :return-value.sync="attributes[field]"
                width="290px"
                >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    :label="$t('fields.rollout.' + field)"
                    v-model="attributes[field]"
                    :disabled="!(nextStatus.includes(validations[field]))"
                    hide-details
                    dense
                    readonly
                    style="background-color: white"
                    class="mb-3"
                    v-bind="attrs"
                    v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker
                  v-model="attributes[field]"
                  scrollable
                  :max="new Date().toISOString().slice(0,10)"
                  :min="prevDate(field, i)"
                  >
                  <!--
                    Insert min-max validation here
                    dea
                  -->
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="modal.splice(i, 1)"
                    >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog[i].save(attributes[field])"
                    >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </template>

          <v-col
            cols="12"
            >
            <v-autocomplete
              outlined
              :items="assignees"
              label="Responsables"
              v-model="attributes.assignees"
              chips
              small-chips
              hide-details
              dense
              multiple
              style="background-color: white"
              :disabled="!['shibui', 'rollout', 'management'].includes(currentUser.currentRoleName) || ['total', 'closed', 'cancelled'].includes(rollout.status)"
              class="mb-3"
              >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  close
                  small
                  close-icon="mdi-check-circle"
                  @click:close="updateSupervisor(data.item)"
                  :color="attributes.supervisor == data.item ? 'success' : ''"
                  >
                  {{ data.item }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>

          <v-col
            cols="12"
            >
            <v-textarea
              outlined
              :label="$t('fields.rollout.obs')"
              v-model="attributes['obs']"
              :disabled="!['shibui', 'rollout', 'management'].includes(currentUser.currentRoleName)"
              hide-details
              dense
              style="background-color: white"
              class="mb-3"
              rows="3"
              ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions
        class="pa-2"
        >
        <div
          class="grow text-right"
          >
          <v-btn
            text
            color="primary"
            class="rounded-lg py-5 mr-2"
            @click="dialog = false"
            >
            Cerrar
          </v-btn>

          <v-btn
            depressed
            color="primary"
            class="rounded-lg py-5"
            @click="edit"
            >
            Guardar
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

import { EditRollout } from '@/graphql/mutations/rollouts'

export default {
  data: () => ({
    modal: [],
    dialog: false,
    fields: [
      'assignationDate',
      'startAt',
      'endsAt',
      'documentationSentAt',
    ],
    assignees: [
      "CRISTOHER RUZ COTAL",
      "DAVID LEIVA CAYULEO",
      "IGNACIO MENESES GUERRA",
      "JONATHAN ANTONIO QUIÑONES GUZMAN",
      "MARCELO GARRIDO NUÑEZ",
      "RODRIGO POZO TRONCOZO",
      "VICTOR MANUEL ORMAZABAL ARAVENA",
      "WILLIAMS ZUÑIGA GODOY",
      "ANDRES ANTONIO GOMEZ LOVERA",
      "KLIBER GERARDO DIAZ GARABAN",
      "ARTURO QUILLA VALENCIA",
      "JONATHAN CASTRO GARRIDO",
      "AGUSTIN APABLAZA ZUÑIGA",
      "FRED NELSON CID ZUÑIGA",
      "ENZO RODRIGO RODRIGUEZ TORO",
      "FRANCISCO ASTORGA CACERES",
      "ARDANY TIMOTE PRADO",
      "CARLOS ORDOÑEZ ABELLO",
      "EDUARDO HERNANDEZ MARTINEZ",
      "EDUARDO VIDAL OLAVE",
      "ELIAS ALBERTO GALDAMEZ NAVARRETE",
      "JONATHAN PAREDES SILVA",
      "LUIS ALEJANDRO DELGADO GUZMAN",
      "LUIS CARLOS MUJICA LOVERA",
      "PATRICIO LOPEZ REYES",
      "SEBASTIAN CARRASCO CID",
      "ISAIAS GONZALEZ RUDIO",
      "GERARDO CONCHA",
      "PEDRO PEÑA",
    ],
    attributes: {
      assignationDate: null,
      startAt: null,
      endsAt: null,
      documentationSentAt: null,
      dpr: null,
      assignees: [],
      obs: null,
    },
    validations: {
      assignationDate: 'assigned',
      startAt: 'installing',
      endsAt: 'installed',
      documentationSentAt: 'pre_approved',
      approvedAt: 'approved',
    }
  }),

  computed: {
    ...mapGetters(['currentUser']),

    nextStatus () {
      var arr = []
      if (this.rollout) {
        var list = ['assigned', 'installing', 'installed', 'pre_approved', 'approved', 'partial']
        var idx = list.indexOf(this.rollout.status)

        if (this.currentUser.currentRoleName == 'documentation' && !(list[idx] == 'installed')) return arr

        if ((idx >= 0) && (idx + 1 < list.length)) {
          if (list[idx] == 'assigned') {
            arr.push(list[idx])
          }
          arr.push(list[idx + 1])
        }
      } 
      return arr
    }
  },

  props: {
    rollout: {
      type: Object,
      required: true
    },
    list: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  created () {
    this.attributes = {
      ...this.attributes,
      ...this.rollout
    }

    delete this.attributes.id
    delete this.attributes.status
    delete this.attributes.workType
    delete this.attributes.technology
    delete this.attributes.nokiaActivities
    delete this.attributes.huaweiActivities
    delete this.attributes.pos
    delete this.attributes.huaweiPos
    delete this.attributes.additionals
    delete this.attributes.createdAt
    delete this.attributes.site
    delete this.attributes.__typename
  },

  methods: {
    edit () {
      this.$apollo.mutate({
        mutation: EditRollout,
        variables: {
          input: {
            id: this.rollout.id,
            attributes: this.attributes
          }
        }
      }).then ( res => {
        this.$emit('reload', res.data.editRollout.rollout.id)
      })
    },

    prevDate (current, idx) {
      if (current == 'assignationDate') return null
      var prev = this.attributes[this.fields[idx - 1]]
      if (!prev) return new Date().toISOString().slice(0,10)
      return new Date(prev).toISOString().slice(0,10)
    },

    updateSupervisor (item) {
      this.attributes.supervisor = item
    }
  }
}
</script>
