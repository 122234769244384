<template>
  <v-dialog
    v-model="dialog"
    width="1000"
    v-if="currentUser"
    >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="white--text rounded-tr-lg"
        style="padding: 21px 0; width: 42px"
        tile
        fab
        small
        depressed
        color="success"
        :disabled="!(['rollout', 'management', 'shibui'].includes(currentUser.currentRoleName))"
        v-bind="attrs"
        v-on="on"
        >
        <v-icon>
          mdi-plus
        </v-icon>
      </v-btn>
    </template>

    <v-card
      v-if="dialog"
      >
      <v-card-title
        class="py-2 primary white--text lower-opacity"
        >
        Rollout
      </v-card-title>

      <v-card-text
        class="pt-3 lower-background-opacity"
        >
        <v-slide-x-transition
          leave-absolute
          >
          <rollout-form
            v-if="tab == 0"
            :site="site"
            @search="search"
            ref="form"
            ></rollout-form>
          <activities-form
            :key="rollout.id"
            :rollout="rollout"
            ref="activitiesForm"
            @reload="reload"
            v-if="project.projectType == 'nokia' && tab == 1"
            ></activities-form>
          <huawei-form
            :key="rollout.id"
            v-if="project.projectType == 'huawei' && tab == 1"
            :rollout="rollout"
            ref="activitiesForm"
            @reload="reload"
            ></huawei-form>
          <additionals-form
            :key="rollout.id"
            v-if="tab == 2"
            :rollout="rollout"
            ref="additionalsForm"
            @reload="reload"
            ></additionals-form>
        </v-slide-x-transition>
      </v-card-text>

      <v-card-actions
        class="pa-2"
        >
        <div
          class="grow text-right"
          >
          <v-btn
            depressed
            outlined
            color="grey"
            class="mr-3 rounded-lg py-5"
            v-if="tab"
            @click="omit"
            >
            Omitir
          </v-btn>

          <v-btn
            depressed
            outlined
            color="grey"
            class="mr-3 rounded-lg py-5"
            v-else
            @click="omit"
            >
            Cancelar
          </v-btn>

          <v-btn
            depressed
            color="primary"
            class="rounded-lg py-5"
            @click="confirm"
            >
            {{ tab == 2 ? 'Guardar y finalizar' : 'Crear y continuar' }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

import RolloutForm from './Form'
import ActivitiesForm from '@/components/sites/main/activities/Form'
import HuaweiForm from '@/components/sites/main/activities/huawei/Form'
import AdditionalsForm from '@/components/sites/main/activities/additionals/Form'

export default {
  data: () => ({
    dialog: false,
    tab: 0
  }),

  props: {
    site: {
      type: Object,
      required: true
    },
    rollout: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    }
  },

  computed: {
    ...mapGetters(['currentUser', 'project'])
  },

  methods: {
    createRollout () {
      this.$refs.form.createRollout()
    },

    editActivities () {
      this.$refs.activitiesForm.editRollout()
    },

    editAdditionals () {
      this.$refs.additionalsForm.editRollout()
    },

    search (id) {
      this.$emit('search', id)
      this.next()
    },

    next () {
      this.$nextTick( () => {
        this.tab += 1
      })
    },

    confirm () {
      if (this.tab == 0) {
        this.createRollout ()
      } else if (this.tab == 1) {
        this.editActivities ()
      } else {
        this.editAdditionals ()
      }
    },

    reload (id) {
      if (this.tab < 2) {
        this.next ()
      } else {
        this.$emit('reload', this.rollout.id)
        this.dialog = false
      }
    },

    omit (id) {
      this.$emit('reload', this.rollout.id)
      this.dialog = false
    }
  },

  components: { RolloutForm, ActivitiesForm, AdditionalsForm, HuaweiForm }
}
</script>
