import gql from 'graphql-tag'

export const CreatePo = gql`
  mutation createPo($input: CreatePoInput!) {
    createPo(input: $input) {
      clientMutationId
      po {
        id
      }
    }
  }
`

export const CreateHuaweiPo = gql`
  mutation createHuaweiPo($input: CreateHuaweiPoInput!) {
    createHuaweiPo(input: $input) {
      clientMutationId
      success
    }
  }
`

export const EditPo = gql`
  mutation editPo($input: EditPoInput!) {
    editPo(input: $input) {
      clientMutationId
      success
    }
  }
`

export const EditHuaweiPo = gql`
  mutation editHuaweiPo($input: EditHuaweiPoInput!) {
    editHuaweiPo(input: $input) {
      clientMutationId
      success
    }
  }
`

export const DeletePo = gql`
  mutation deletePo($input: DeletePoInput!) {
    deletePo(input: $input) {
      clientMutationId
      success
    }
  }
`

export const DeleteHuaweiPo = gql`
  mutation deleteHuaweiPo($input: DeleteHuaweiPoInput!) {
    deleteHuaweiPo(input: $input) {
      clientMutationId
      success
    }
  }
`

export const Bill = gql`
  mutation bill($input: BillInput!) {
    bill(input: $input) {
      clientMutationId
      success
    }
  }
`

export const EditPoLine = gql`
  mutation editPoLine($input: EditPoLineInput!) {
    editPoLine(input: $input) {
      clientMutationId
      success
    }
  }
`
