<template>
  <v-dialog
    v-model="dialog"
    width="500"
    >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :disabled="disabled"
        color="primary"
        text
        v-bind="attrs"
        v-on="on"
        v-if="!list"
        >
        {{ disabled ? (activity == 'additional' ? 'Adicionales validados' : 'Actividades validadas') : (activity == 'additional' ? 'Validar adicionales' : 'Validar actividades') }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        Validar {{ activity == "additional" ? 'adicionales' : 'actividades' }}
      </v-card-title>

      <v-card-text>
        ¿Está seguro de que desea validar las tareas cargadas<template v-if="rollout"> en el <span class="black--text font-weight-medium">Rollout: {{ rollout.id }} - Sitio: {{ rollout.site.siteId }}</span></template>? Esta acción <span class="red--text font-weight-medium">no se puede deshacer</span>.
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="dialog = false"
          >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          @click="close"
          >
          Validar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { CloseActivity } from '@/graphql/mutations/activities'

export default {
  data: () => ({
    dialog: false
  }),

  props: {
    disabled: {
      type: Boolean,
      required: true
    },

    activity: {
      type: String,
      required: true
    },

    id: {
      type: Number | String,
      required: true
    },

    rollout: {
      type: Object,
      required: false,
    },
    
    list: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  methods: {
    close () {
      this.$apollo.mutate({
        mutation: CloseActivity,
        variables: {
          input: {
            id: this.id,
            activityType: this.activity
          }
        }
      }).then ( res => {
        this.$emit('reload')
        this.dialog = false
      })
    }
  }
}
</script>
